<template>
    <div class="home">
        <b-img class="" overlay style="height: 150px; width: 100%; object-fit: cover; object-position: 10% 30%; transform: scale(1.5);" src="images/bild.jpg" no-wrap center fluid />
        <br />
        <br />
        <span v-html="$t('presentation.procurement')" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'einkauf',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>