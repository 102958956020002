<template>
    <div>
        <b-card class="mb-3 shadow">
            <b-alert show v-if="questionnaire.requesttext" variant="info">
                <b-form-group v-if="questionnaire.requesttext" label-for="questionnaire-requesttext">
                    <b-row class="ml-1" v-html="newlineToBr(questionnaire.requesttext)" id="questionnaire-requesttext"></b-row>
                </b-form-group>
            </b-alert>
            <h3>{{questionnaire.name}}</h3>
            <b-form @submit.prevent="submitForm()">
                <template v-for="question in questionnaire.questions">
                    <b-form-group v-bind:key="question.id" :label="question.questiontext + (question.required ? '*' : '')">
                        <template v-if="question.type === 1">
                            <b-form-input type="number" :name="'question-'+question.id" v-model.trim="answers[question.id]" :state="$v['answers'][question.id].$error ? false : null"/>
                        </template>
                        <template v-if="question.type === 2">
                            <b-form-input type="number" :name="'question-'+question.id" v-model.trim="answers[question.id]" :state="$v['answers'][question.id].$error ? false : null"/>
                        </template>
                        <template v-if="question.type === 3 && (question.selection === null || question.selection === '')">
                            <b-form-input type="text" :name="'question-'+question.id" v-model.trim="answers[question.id]"  :state="$v['answers'][question.id].$error ? false : null"/>
                        </template>
                        <template v-if="question.type === 3 && (question.selection !== null && question.selection !== '')">
                            <b-form-select :name="'question-'+question.id" v-model.trim="answers[question.id]">
                                <template v-for="value in question.selection.split(';')">
                                    <option v-bind:key="'question-'+question.id+'-'+value" :value="value">{{value}}</option>
                                </template>
                            </b-form-select>
                        </template>
                        <template v-if="question.type === 4">
                            <b-form-file :name="'question-'+question.id" v-model="answers[question.id]" placeholder="Datei wählen..."></b-form-file>
                            <b-button v-if="answers[question.id+'_downloadKey']" @click.prevent="downloadFile(answers[question.id+'_downloadKey'], answers[question.id+'_filename'], answers[question.id+'_mimetype'])">Datei herunterladen</b-button>
                        </template>
                    </b-form-group>
                </template>
                <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("questionnaire.btn_save") }}</b-button>
                <b-button class="button-margin" v-if="(questionnaire.request == 0 || questionnaire.request == 1)" @click.prevent="downloadQuestionnaire(questionnaire.id)"><b-icon icon="download" />{{ $t("questionnaire.btn_download") }}</b-button>
            </b-form>
        </b-card>
        <br />
    </div>
</template>
<script>
import questionnaireService from '../../../../services/QuestionnaireService';
import { helpers, required } from 'vuelidate/lib/validators';
import DownloadService from '../../../../services/DownloadService';

export default {
    name: 'profile-questionnaire',
    props:['questionnaire', 'answers'],
    data() {
        return {
            file: {}
        }
    },
    validations() {
        let validations = {
            answers: {}
        };

        this.questionnaire.questions.forEach(question => {
            validations['answers'][question.id] = {};

            if(question.required) {
                validations['answers'][question.id]['required'] = required;
            }

            if(question.check_regex) {
                const customValidator = (value) => {
                    if(value === undefined || value === null) {
                        return true;
                    }

                    return new RegExp(question.check_regex).test(value);
                }

                validations['answers'][question.id]['regex'] = customValidator;
            }

            if(question.check_eval) {
                    const customValidator = (value) => {
                    if(value === undefined || value === null || value === '') {
                        return true;
                    }

                    return eval(''+value+question.check_eval);
                }

                validations['answers'][question.id]['eval'] = customValidator;
            }
        });

        return validations;
    },
    created() {

    },
    methods: {
        submitForm() {
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }

            let values = {};

            this.questionnaire.questions.forEach(q => {
                values[q.id] = this.answers[q.id];
            });

            questionnaireService.saveQuestions(this.questionnaire.id, values)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.label_success"),
                            type: 'success',
                            text: this.$t("questionnaire.label_message")
                        });
                    }
                });
        },
        downloadQuestionnaire(questionnaireId) {
            questionnaireService.downloadQuestionnaire(questionnaireId)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'fragebogen.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        downloadFile(downloadKey, filename, mimetype) {
            questionnaireService.downloadFile(downloadKey)
                .then(response => {
                    DownloadService.processDownload(response, filename, mimetype);
                });
        },
        newlineToBr: function (value) {
            return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        }
    },
    computed: {
        fieldState() {
            return quesionId => this.$v['question-'+quesionId].$error ? null : false;
        }
    }
};
</script>
