<template>
<div class="card shadow" v-if="doneLoading">
    <div class="card-header">{{ $t("register.header") }}</div>
    <div class="card-body">
        <p v-if="!isValidRequest">
            {{ $t("register.invalid") }}
            <template v-if="validity_message"><br />{{ validity_message }}</template>
        </p>
        <p v-if="registrationSuccess">
            {{ $t("register.success") }}
        </p>
        <b-form @submit.prevent="submitRegistration" v-if="isValidRequest && !registrationSuccess">
            <b-form-group :label="$t(`register.company`)" label-for="user-company">
                <b-form-input id="user-company" type="text" v-model="user.company" :state="!$v.user.company.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
            </b-form-group>

            <b-form-group :label="$t(`register.name`)" label-for="user-name">
                <b-form-input id="user-name" type="text" v-model="user.name" :state="!$v.user.name.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
            </b-form-group>

            <b-form-group :label="$t(`register.email`)" label-for="user-email" >
                <b-form-input id="user-email" type="text" v-model="user.email" :state="!$v.user.email.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
            </b-form-group>

            <b-form-group :label="$t(`register.password`)" label-for="user-password">
                <b-form-input id="user-password" type="password" v-model="user.password" :state="!$v.user.password.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
            </b-form-group>

            <b-form-group :label="$t(`register.password_repeat`)" label-for="user-password-repeat">
                <b-form-input id="user-password-repeat" type="password" v-model="user.password_repeat" :state="!$v.user.password_repeat.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary"><b-icon icon="check2-square" />{{ $t("register.submit") }}</b-button>

            <br/>
            <br/>
            <span v-html="$t('register.instruction')" />

        </b-form>
    </div>
</div>
</template>

<script>
import { helpers, required, email, sameAs, minLength } from 'vuelidate/lib/validators';
import userService from '../../services/UserService';

export default {
    name: 'registration',
    validations() {
        return {
            user: {
                company: {
                    required
                },
                name: {
                    required
                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(2)
                },
                password_repeat: {
                    required,
                    sameAsPassword: sameAs('password')
                }
            }
        };
    },
    data() {
        return {
            doneLoading: false,
            isValidRequest: false,
            registrationSuccess: false,
            user: {
                code:''
            }
        };
    },
    created() {
        if(!this.$route.params.code) {
            this.isValidRequest = false;
        }else {
            this.$store.commit('loading/setLoading', true);
            userService.validateInvitationLink(this.$route.params.code)
                .then(response => {
                    if(response.data.success) {
                        this.isValidRequest = true;
                        this.user.code = this.$route.params.code;
                    }else {
                        this.isValidRequest = false;
                        this.validity_message = response.data.message;
                    }

                    this.doneLoading = true;
                    this.$store.commit('loading/setLoading', false);
                });
        }
    },
    computed: {
    },
    methods: {
        submitRegistration() {
            this.$v.$touch();

            if(!this.$v.$invalid) {
                this.$store.commit('loading/setLoading', true);

                userService.register(this.user)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);

                        if(response.data.success) {
                            this.registrationSuccess = true;
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("register.title"),
                                type: 'error',
                                text: this.$t("register.errormessage") // response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
            }
        }
    }
}
</script>
