<template>
<div class="card shadow" v-if="doneLoading">
    <div class="card-header">{{ $t("passwordreset.title") }}</div>
    <div class="card-body">
        <p v-if="!isValidRequest">
            {{ $t("passwordreset.message") }}
        </p>
        <b-form @submit.prevent="sendReset(user)" v-if="isValidRequest">
            <b-form-group :label="$t(`passwordreset.email`)" label-for="user-email" >
                <b-form-input id="email" type="email" v-model="user.email" :state="!$v.user.email.$error ? null : false"></b-form-input>
            </b-form-group>

            <b-form-group :label="$t(`passwordreset.password`)" label-for="password">
                <b-form-input id="password" type="password" v-model="user.password" :state="!$v.user.password.$error ? null : false"></b-form-input>
            </b-form-group>

            <b-form-group :label="$t(`passwordreset.password_repeat`)" label-for="user-password-repeat">
                <b-form-input id="user-password-repeat" type="password" v-model="user.password_confirmation" :state="!$v.user.password_confirmation.$error ? null : false"></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary"><b-icon icon="check2-square" />{{ $t("passwordreset.password_submit") }}</b-button>
        </b-form>
    </div>
</div>

</template>

<script>

import { mapActions } from 'vuex';
import { helpers, required, email, sameAs, minLength } from 'vuelidate/lib/validators';
import Auth from '../../services/AuthService';

export default {
    name: 'resetpassword',
    validations() {
        return {
            user: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(2)
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs('password')
                }
            }
        };
    },
    data() {
        return {
            doneLoading: false,
            isValidRequest: false,
            user: ({
                token: '',
                email: '',
                password: '',
                password_confirmation: '',
            })
        }
    },
    created() {
        if(!this.$route.params.token || !this.$route.params.email) {
            this.isValidRequest = false;
        }else {
            this.isValidRequest = true;
            this.user.token = this.$route.params.token;
            this.user.email = this.$route.params.email;
        }
        this.doneLoading = true;
    },

    methods: {
        ...mapActions({
            requestReset: 'auth/reset'
        }),
        sendReset (user) {
            this.$v.$touch();

            if(!this.$v.$invalid) {
                this.requestReset(user)
                    .then(data => {
                        this.$notify({
                            group: 'global',
                            title: this.$t("passwordreset.title"),
                            type: 'success',
                            text: this.$t("passwordreset.success")
                        });
                        this.$router.push({path: '/'});
                    })
                    .catch(error => {
                        this.$notify({
                            group: 'global',
                            title: this.$t("passwordreset.title"),
                            type: 'error',
                            text: this.$t("passwordreset.errormessage"),
                        });
                    });
            }
        }
    }
};

</script>
