<template>
<div class="card shadow" v-if="doneLoading">
    <div class="card-header">{{ $t("activation.header") }}</div>
    <div class="card-body">
        <p v-if="activationSuccess">
            {{ $t("activation.success") }}
        </p>
        <p v-if="!activationSuccess">
            {{message}}
        </p>
    </div>
</div>
</template>

<script>
import userService from '../../services/UserService';

export default {
    name: 'activation',
    data() {
        return {
            doneLoading: false,
            activationSuccess: false,
            message: ''
        };
    },
    created() {
        if(!this.$route.params.code) {
            this.isValidRequest = false;
        }else {
            this.$store.commit('loading/setLoading', true);
            userService.activate(this.$route.params.code)
                .then(response => {
                    this.activationSuccess = response.data.success === true;

                    if(!response.data.success) {
                        this.message = response.data.message;
                    }

                    this.doneLoading = true;
                    this.$store.commit('loading/setLoading', false);
                });
        }
    }
}
</script>
