<template>
    <div>
        <b-container>  
            <b-row align-h="center" class="mt-n5 text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="1" cols-xl="1">
                <b-img class="" overlay style="height: 200px; width: 100vh; object-fit: cover; object-position: 10% 30%; transform: scale(1.5);" src="images/bild.jpg" no-wrap center rounded />
                <b-col style="position: absolute;" class="mt-5 p-md-5 p-sm-5 p-xs-5" xl="8" lg="12" md="12" sm="12">
                    <b-row align-h="center">
                        <p class="px-3 title" style="position: absolute;">Step into Digital Sourcing</p>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
  
        <b-container>
            <b-row align-h="center" class="mx-n5" style="background-color: #F3EF83; min-height: 60px;">
            </b-row> 
            <b-row align-h="center" class="mx-n5 text-center" style="background-color: #F3EF83; min-height: 200px;">
                <b-col class="mt-3 p-md-5 p-sm-5 p-xs-5" xl="7" lg="12" md="12" sm="12">
                    <b-row class="d-flex">
                        <p class="px-3 title_2">Zwei voll in Ihr System integrierte Module heben Einkaufs&#173prozesse auf ein neues Level</p>
                        <p class="px-3" style="font-size: 1rem; font-weight: thin;">
                            <strong>diils</strong> ist eine individuelle Plattformlösung, mit der Sie bekannte Schwachstellen und Zeitfresser in herkömmlichen Beschaffungsprozessen hinter sich lassen. Sie beinhaltet alle wesentlichen Tools zur 
                            Einkaufs- und Kostenoptimierung. Sie bündelt eine individualisierbare Ausschreibungs- und Auktionssoftware mit einer Vertragsverwaltung, integriertem Fristenmanagement und zahlreichen weiteren Tools 
                            zur Lieferantensteuerung. Ausschreibungen, Auktionen, Lieferantenselbstauskünfte, Projekte, Verträge und Zertifikate werden ganzheitlich steuerbar.
                        </p>
                    </b-row>
                </b-col>
            </b-row>
            <b-row align-h="center" class="mt-5">
                <h1>Einkaufs- und Kostenmanagement
                </h1>
            </b-row>
            <b-row align-h="center" class="mx-n5">
                <b-col class="p-md-5 p-sm-5 p-xs-5" xl="8" lg="12" md="12" sm="12">
                    <b-row class="text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="4">
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-process>
                            <b-icon icon="calendar2-month" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Konzeption und Planung von Ausschrei&#173bungs&#173prozessen</h5>
                            <p class="mt-4 text-muted">Zeitliche Vorplanung und Ressourcen&#173zuordnung für alle anstehenden Anfrage- und Ausschreibungs&#173prozesse</p>
                            <b-modal centered id="modal-process" size="lg" title="Konzeption und Planung von Ausschreibungsprozessen">
                                <p>
                                    Das gesamte Ausschreibungsmanagement und begleitende Lieferantenaktionen können strukturiert und zeitlich sowie ressourcenseitig vorgeplant werden. Jede Abteilung und jede Gesellschaft kann individuell oder zentral eine Zeitplanung und Meilensteine einstellen.
                                </p>
                                <ul>
                                    <li>Transparenz über alle Ausschreibungen, Auktionen und sonstiger Aktionen im Beschaffungsmarkt (Lieferantenselbstauskünfte, LkSG, Lieferantenkommunikaiton usw.)</li>
                                    <li>Möglichkeit zur zentralen Qualitätssicherung</li>
                                    <li>Professionalisierung und Standardisierung von Einkaufsprozessen</li>
                                    <li>Zuordnung von Teams zu einzelnen Marktaktionen</li>
                                    <li>Kollaborative Zusammenarbeit</li>
                                    <li>Transparenz und Stueuerung der Prozesse über Trackpoints</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-state>
                            <b-icon icon="cloud-arrow-up" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Veröffent&#173lichung von Ausschrei&#173bungen und Auktionen</h5>
                            <p class="mt-4 text-muted">Veröffentlichung von Ausschreibungen oder Auktionen mit nur einem Click</p>
                            <b-modal centered id="modal-state" size="lg" title="Veröffentlichung von Ausschreibungen und Auktionen">
                                <p>
                                    Allen Entwürfen für Ausschreibungsvorgänge, Auktionen oder sonstigen Lieferantenaktionen können jeweils unterschiedliche Status-Möglichkeiten zugewiesen werden. 
                                </p>
                                <ul>
                                    <li>Aktionen können auf den Status "in Bearbeitung" gesetzt werden, damit das Team kollaborativ die Unterlagen und Informationen strukturiert zusammenstellen kann.</li>
                                    <li>Im Status "Aktiv" werden Vergaben für Bieter und Lieferanten sichtbar.</li>
                                    <li>Eine Umstellung auf den Status "Archiviert" sperrt alle weiteren Veränderungen. Ein gesamter archivierter und abgeschlossener Vergabeprozess kann duplizert werden und für z.B. einen Anschlussvertrag im Folgejahr nochmals veröffentlicht werden.</li>
                                    <li>Zudem kann jeder Vergabeentwurf oder fertige Ausschreibung als Template und Vorlage für weitere Vergaben angelegt werden.</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-auction>
                            <b-icon icon="gear" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Einstellung verschiedener Auktionstypen</h5>
                            <p class="mt-4 text-muted">Über die Einstellung unterschiedlicher Auktionsparemeter kann die Preispsychologie in der Angebotsphase vielfältig gestaltet werden</p>
                            <b-modal centered id="modal-auction" size="lg" title="Einstellung verschiedener Auktionstypen">
                                <p>
                                    Es können vielfältige Auktionsparameter eingestellt werden, um den Prozess der Preisbildung ideal an die individuelle Preisbereitschaft der Bieter anzupassen. Das Erfordernis und der Zeitaufwand für Nachverhandlungen sollten idealerweise weitgehend minimiert werden, sodass der Endpreis eines Vergabeprozesses mit der finalen Preisstellung nahezu identisch ausfällt.
                                </p>
                                <p>
                                    Einstellbar sind folgende Auktionstypen:
                                </p>
                                <ul>
                                    <li>Gesamtrang-Auktionen</li>
                                    <li>Positionsrang-Auktionen</li>
                                    <li>Parametrische Auktionen</li>
                                    <li>Sealed-Bid Auktionen</li>
                                    <li>Bei Bedarf können auch Reverse Dutch Auctions oder Japanese Auctions und andere Auktionstypen mit individueller Parametrisierung (z.B. für tick-value und tick-time) bereitgestellt werden.</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-archive>
                            <b-icon icon="folder-plus" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Archivierungs&#173funktion</h5>
                            <p class="mt-4 text-muted">Archivierungs&#173möglichkeit aller Ausschreibungs&#173vorgänge und Duplizierbarkeit aller zugehörigen Einstellungen als Ausschreibungs&#173vorlage</p>
                            <b-modal centered id="modal-archive" size="lg" title="Archivierung von Vergabeprozessen">
                                <p>
                                    Alle Vergabeprozesse oder sonstigen Lieferantenaktionen können nach Abschluss archiviert werden. Die Vergabe wird in das Archiv verschoben und kann dort jederzeit durch die Bieter oder den Veranstalter eingesehen werden.
                                </p>
                                <ul>
                                    <li>Das Archiv dient als unternehmensweites Wissensarchiv für den Einkauf und beinhaltet sämtliche Ausschreibungsdokumente zu allen druchgeführten Ausschreibungsprozessen.</li>
                                    <li>Es beinhaltet zudem die vollständigen Angebotsunterlagen aller Bieter, inklusive der kompletten Gebotshistorie der Bieter für jede Ausschreibungsposition.</li>
                                    <li>Eine Umstellung auf den Status "Archiviert" sperrt alle weiteren Veränderungen.</li>
                                    <li>Ein gesamter archivierter und abgeschlossener Vergabeprozess kann duplizert werden und steht damit für z.B. eine erneute Veröffentlichung bereit.</li>
                                    <li>In der Rückschau kann damit jede Vergabeentscheidung sicher und vollständig dokumentiert werden und ist damit insbesondere vor dem Hintergrund von internen Compliance-Anforderungen ein wertvolles Instrument.</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    </b-row>
                    <b-row class="text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="4">
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-documents>
                            <b-icon icon="file-earmark-diff" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Dokumenten&#173zusammen&#173stellung</h5>
                            <p class="mt-4 text-muted">Kollaborative Weiterentwicklung und Zuordnung notwendiger Ausschreibungs&#173dokumente</p>
                            <b-modal centered id="modal-documents" size="lg" title="Dokumentenzusammenstellung">
                                <p>
                                    Verschiedene Teammitglieder können an der Zusammenstellung der unterschiedlichen Ausschreibungsdokumente mitwirken. Sö können Ausschreibungsinformationen kollaborativ zusammengetragen werden. 
                                </p>
                                <ul>
                                    <li>Sämtliche kalkulations- und vergaberelevanten Dokumente können auf diils archiviert und ausgewählten Ausschreibungsprozessen zugeordnet werden.</li>
                                    <li>Die unterschiedlichen Team Mitglieder oder Benutzer eine Ausschreibung können unabhängig voneinander relevante Ausschreibungsdokumente hochladen.</li>
                                    <li>Zentrale Dokumente wie zum Beispiel die Allgemeinen Einkaufsbedingungen, Unternehmenspräsentationen, Produktspezifikationen oder der Code of Conduct können als Vorlage zur Verfügung gestellt werden.</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-tracking>
                            <b-icon icon="link" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Tracking der Registrierungs- und Gebotsverläufe</h5>
                            <p class="mt-4 text-muted">Tracking im gesamten Anmelde- und Preisfindungs&#173prozess</p>
                            <b-modal centered id="modal-tracking" size="lg" title="Tracking der Registrierungs- und Gebotsverläufe">
                                <p>
                                    Die Nachverfolgung der Bieteraktivitäten stellt im Rahmen des Bietermanagements bei Vergabeprozessen ein wichtiges Element zur Qualitäts- und Ergebnissicherung dar.
                                    Das Tracking ist hierbei auf wenige elementare Prozesselemente beschränkt:
                                </p>
                                <ul>
                                    <li>Registrierungsstatus</li>
                                    <li>Authentifizierungsstatus</li>
                                    <li>Login-Historie</li>
                                    <li>Geburtsverläufe</li>
                                    <li>Zudem kann jeder Bieter zu einzelnen Vergabeprozessen den eigenen Angebotsstatus freiwillig zur Verfügung stellen.</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-templates>
                            <b-icon icon="folder2-open" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Ausschreibungs&#173vorlagen</h5>
                            <p class="mt-4 text-muted">Einstellung von Ausschrei&#173bungs&#173vorlagen zur einheitlichen Verwendung im Unternehmen</p>
                            <b-modal centered id="modal-templates" size="lg" title="Ausschreibungsvorlagen">
                                <p>
                                    diils bietet die Möglichkeit, individuelle Ausschreibungstemplates anzulegen.
                                    Diese Vorlagen können für einzelne Warengruppen oder auch übergeordnet für gesamte Warenkategorien angelegt werden.
                                    Folgende Inhalte kann ein Ausschreibungstemplate umfassen:
                                </p>
                                <ul>
                                    <li>Ein Leistungs- und Artikelportfolio inklusive Spezifikationen und Mengenangaben.</li>
                                    <li>Eine Zusammenstellung zugehöriger Ausschreibungsdokumente wie zum Beispiel Produktdatenblätter Bearbeitungshinweise und allgemeine Einkaufsbedingungen u.ä.</li>
                                    <li>Voreinstellungen zu Auktionsformaten</li>
                                    <li>Vordefinierte Benutzergruppen</li>
                                    <li>Eine Vorauswahl bereits zugewiesener Bieter</li>
                                    <li>Vorformulierte Textbausteine zu Vergabe- und Vertragsbedingungen</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-questionnaire>
                            <b-icon icon="info-circle" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Lieferanten&#173selbstauskunft</h5>
                            <p class="mt-4 text-muted">Individuelle Erstellung von Lieferanten&#173selbstauskünften als Online-Formular für die Bieter</p>
                            <b-modal centered id="modal-questionnaire" size="lg" title="Lieferantenselbstauskunft">
                                <p>
                                    Innerhalb von diils ist es möglich, nahezu jede Art von Fragebogen digital abzubilden. Ein Fragebogen kann im Rahmen einer klassischen Lieferantenselbstauskunft gestaltet werden oder für eine Risikobewertung der Lieferanten im Rahmen des Lieferkettensorgfaltspflichtengesetzes (LkSG) inkl. eines frei skalierbaren Scoring-Systems. 
                                    egal ob 50, 500 oder 5000 Lieferanten, der gesamte Prozess wird digital unterstützt und sämtliche Antworten der Lieferanten werden strukturiert dokumentiert.
                                </p>
                                <ul>
                                    <li>Freie Gestaltung von Fragebögen</li>
                                    <li>Festlegung der Antwortformate</li>
                                    <li>Freie Vorgabe von Antwortoptionen oder Auswahlfeldern</li>
                                    <li>Antwortoptionen können spezifische Score-Werte zugeordnet werden</li>
                                    <li>Alle beantworteten Fragen und Fragebögen werden transparent über alle Lieferanten oder Bieter in einer Vergleichsmatrix abgebildet</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    </b-row>
                    <b-row class="text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="4">
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-invitations>
                            <b-icon icon="arrows-move" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Bieter&#173steuerung</h5>
                            <p class="mt-4 text-muted">Einladung definierter Bieter über Einladungs&#173links und auto&#173matische Zuordnung zu Ausschreibungen</p>
                            <b-modal centered id="modal-invitations" size="lg" title="Bietersteuerung">
                                <p>
                                    Alle Bieter und Lieferanten werden mit einem Einladungslink auf die Plattform geleitet, womit der Registrierungs- und Authentifizierungsprozess gestartet wird.
                                    Der Einladungsprozess beinhaltet unterschiedliche Funktionalitäten zur Bietersteuerung:
                                </p>
                                <ul>
                                    <li>Anmeldung über einen 2-stufigen Registrierungs- und Authentifizierungsprozess</li>
                                    <li>Genehmigungsprozess für DSGVO- und Nutzungsbedingungen</li>
                                    <li>Automatische Zuweisung der Bieter zu hinterlegten Vergabevorgängen</li>
                                    <li>Tracking des Anmeldestatus je Bieter</li>
                                    <li>Automatische eMail-Information über neu registrierte Benutzer</li>
                                    <li>Gültigkeits- und Sicherheitseinstellungen für Einladungslinks insbesondere zum Schutz vor nicht authorisiertem Zugang zur Beschaffungsplattform</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-overview>
                            <b-icon icon="grid3x3-gap" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Automatische Angebots&#173auswertungen</h5>
                            <p class="mt-4 text-muted">Automatische Gebotsimporte, Live-Auswertungen, Preistableaus und Rangdarstellungen mit jeder Preisaktualisierung durch die Bieter</p>
                            <b-modal centered id="modal-overview" size="lg" title="Automatische Angebotsauswertungen">
                                <p>
                                    Alle Gebote zu Ausschreibungen oder Auktionen für das gesamte Artikelportfolio werden über die Beschaffungsplattform konsolidiert und in einem Preisspiegel abgebildet.
                                    Der Gebots- und Auswertungsumfang umfasst eine Vielzahl von Funktionen:
                                </p>
                                <ul>
                                    <li>Wahlweise manuelle Gebotseingabe oder Gebots-Import für das gesamte Leistungsportfolio.</li>
                                    <li>Konsolidierung aller Einzelgebote und Aufbereitung zu einem Preisspiegel.</li>
                                    <li>Automatische Rangsortierung aller Bieter.</li>
                                    <li>Grafische Abbildung sämtlicher Preiseingaben.</li>
                                    <li>Darstellung der Preisdynamik je Position oder für eine gesamte Vergabe.</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-conditions>
                            <b-icon icon="textarea-t" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Vergabe&#173bedingungen mit frei gestaltbaren Textbausteinen</h5>
                            <p class="mt-4 text-muted">Vorformulierte Ausschreibungs&#173texte und ein integriertes Baukasten-Modell für Vergabe&#173bedingungen</p>
                            <b-modal centered id="modal-conditions" size="lg" title="Vergabebedingungen mit frei gestaltbaren Textbausteinen">
                                <p>
                                    Vergabebedingungen, Vertragskomponenten, Lieferbedingungen oder allgemeine Regelungen zu kaufmännischen Abwicklungsprozessen: viele Textelemente wiederholen sich von Ausschreibung zu Ausschreibung und können unverändert als Textbaustein wiederverwendet werden. diils bietet die Möglichkeit frei formulierte Texte als Textvorlage, bzw. als Textbaustein für sämtliche Vergabeprozesse einzurichten. Dadurch wird die Qualität der erstellten Ausschreibungsdokumente deutlich verbessert und ein unternehmensübergreifender Standard kann etabliert werden.
                                </p>
                                <ul>
                                    <li>Frei formulierbare Textbausteine</li>
                                    <li>Einfacher Zugriff über einblendbare Sidebars</li>
                                    <li>Positionierung von kontextbasierten Info-Buttons mit Textbausteinen in den jeweiligen Gliederungselementen der Ausschreibungsdokumente</li>
                                    <li>Einfache Copy-to-Clipboard Funktionalität</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard" v-b-modal.modal-customizing>
                            <b-icon icon="code-slash" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>Freies Customizing</h5>
                            <p class="mt-4 text-muted">Freies Customizing von Zusatzfunktionen oder ergänzenden Darstellungen, z.B. Dashboards, ERP-Anbindung etc.</p>
                            <b-modal centered id="modal-customizing" size="lg" title="Freies Customizing">
                                <p>
                                    diils liefert sehr umfangreiche Grundfunktionalitäten und Prozessmuster für eine Vielzahl von Prozessen im Einkauf und im Lieferantenmanagement. Falls dennoch einzelne Funktionalitäten fehlen sollten, können nahezu beliebige Zusatzfunktionen, Workflows oder Darstellungen ergänzt und angepasst werden.
                                </p>
                                <ul>
                                    <li>Anpassung der Beschaffungsplattform an das CI des Unternehmens</li>
                                    <li>Ergänzung um Zusatzfunktionen</li>
                                    <li>Erweiterung um Import- und Exportfunktionen</li>
                                    <li>Individualisierbare Anpassung an bestehende Workflows</li>
                                    <li>Weiterführende Tools zur Automatisierung, z.B. im Rahmen des Lieferkettensorgfaltspflichtengesetzes (LkSG)</li>
                                </ul>
                            </b-modal>
                        </b-col>
                    </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
  
      
        <b-container>
            <b-row align-h="center" class="mx-n5 text-center" style="background-color: #DCD7D3; min-height: 200px;">
                <b-col class="mt-3 p-md-5 p-sm-5 p-xs-5" xl="7" lg="12" md="12" sm="12">
                    <b-row align-h="center" class="d-flex text-center">
                        <p align-h="center" class="px-3 text-center title_2">Für uns das Wichtigste: <br /> Kein Vertrag verlängert sich ungeprüft automatisch</p>
                        <p class="px-3 text-center" style="font-size: 1rem; font-weight: thin;">
                            Das Vertrags- und Fristen&#173management bringt Struktur in Ihre Rahmen&#173vereinbarungen und Kontrakte aller Art. Der Vertrags&#173lebenszyklus wird aktiv steuerbar. Individuelle Wiedervorlagen erinnern an Kündigungsfristen, Termine oder zukünftige Aufgaben.
                        </p>
                    </b-row>
                </b-col>
            </b-row>
            <b-row align-h="center" class="mt-5"><h1>Vertrags- und Fristen&#173management</h1></b-row>
            <b-row align-h="center" class="mx-n5">
                <b-col class="p-md-5 p-sm-5 p-xs-5" xl="8" lg="12" md="12" sm="12">
                    <b-row class="text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="4">
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="calendar2-month" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_1") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_1") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="bell" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_2") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_2") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="arrow-repeat" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_3") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_3") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="arrows-fullscreen" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_4") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_4") }}</p>
                        </b-col>
                    </b-col>
                    </b-row>
                    <b-row class="text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="4">
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="file-pdf" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_5") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_5") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="search" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_6") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_6") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="diagram3" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_7") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_7") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="intersect" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_8") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_8") }}</p>
                        </b-col>
                    </b-col>
                    </b-row>
                    <b-row class="text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="4">
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="chat-left-text" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_9") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_9") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="bookmark-plus" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_10") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_10") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="key" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_11") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_11") }}</p>
                        </b-col>
                    </b-col>
                    <b-col class="p-3 p-md-2 d-flex">
                        <b-col class="infocard">
                            <b-icon icon="code-slash" font-scale="2" class="mb-2 text-muted"></b-icon>
                            <h5>{{ $t("landingpage.cardheader_12") }}</h5>
                            <p class="mt-4 text-muted">{{ $t("landingpage.cardtext_12") }}</p>
                        </b-col>
                    </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
  
        <b-container>
            <b-row align-h="center" class="mx-n5 text-center" style="background-color: #0A2828; min-height: 200px;">
                <b-col class="mt-3 p-md-5 p-sm-5 p-xs-5" xl="7" lg="12" md="12" sm="12">
                    <b-row align-h="center" class="d-flex text-center">
                        <p align-h="center" class="px-3 text-center title_2" style="color: #E9EFF0;">Lieferketten&#173sorgfalts&#173pflichten&#173gesetz:<br />Identifizieren Sie Ihre Risiko&#173potenziale.<br />Steuern Sie Ihr Lieferanten&#173portfolio.</p>
                        <p class="px-3 text-center" style="font-size: 1rem; font-weight: thin; color: #869C9F;">
                            <strong>diils</strong> hilft dabei, das Risikoprofil des gesamten Lieferantenportfolios gemäß den Vorgaben aus dem Lieferkettensorgfaltspflichtengesetz zu erfassen. diils liefert jährlich aktualisierte Risikobewertungen hinsichtlich Menschenrechts- und Umweltrisiken, welche je Land und je Branchencluster bereitgestellt werden.
                            Zusätzlich stellen die Lieferanten auf der Plattform individuelle Risikoinformationen, Dokumente und relevante Zertifikate bereit aus denen entsprechender Handlungsbedarf abgeleitet wird.
                            Ein Großteil der Anforderungen aus dem LkSG kann hiermit bereits abgedeckt werden. Bei der Umsetzung weiterer, z.B. organisatorischer Anforderungen, helfen Ihnen unsere spezialisierten Berater gerne weiter. Informieren Sie sich...
                        </p>
                    </b-row>
                </b-col>
            </b-row>

            <b-row align-h="center" class="mt-5"><h1>Lieferketten&#173sorgfaltspflichten&#173gesetz (LkSG)</h1></b-row>
            <b-row align-h="center" class="mx-n5">
                <b-col class="p-md-5 p-sm-5 p-xs-5" xl="8" lg="12" md="12" sm="12">
                    <b-row class="text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="4">
                        <b-col class="p-3 p-md-2 d-flex">
                            <b-col class="infocard">
                                <b-icon icon="shield-check" font-scale="2" class="mb-2 text-muted"></b-icon>
                                <h5>{{ $t("landingpage.lksg_cardheader_1") }}</h5>
                                <p class="mt-4 text-muted">{{ $t("landingpage.lksg_cardtext_1") }}</p>
                            </b-col>
                        </b-col>
                        <b-col class="p-3 p-md-2 d-flex">
                            <b-col class="infocard">
                                <b-icon icon="globe2" font-scale="2" class="mb-2 text-muted"></b-icon>
                                <h5>{{ $t("landingpage.lksg_cardheader_2") }}</h5>
                                <p class="mt-4 text-muted">{{ $t("landingpage.lksg_cardtext_2") }}</p>
                            </b-col>
                        </b-col>
                        <b-col class="p-3 p-md-2 d-flex">
                            <b-col class="infocard">
                                <b-icon icon="layers" font-scale="2" class="mb-2 text-muted"></b-icon>
                                <h5>{{ $t("landingpage.lksg_cardheader_3") }}</h5>
                                <p class="mt-4 text-muted">{{ $t("landingpage.lksg_cardtext_3") }}</p>
                            </b-col>
                        </b-col>
                        <b-col class="p-3 p-md-2 d-flex">
                            <b-col class="infocard">
                                <b-icon icon="building" font-scale="2" class="mb-2 text-muted"></b-icon>
                                <h5>{{ $t("landingpage.lksg_cardheader_4") }}</h5>
                                <p class="mt-4 text-muted">{{ $t("landingpage.lksg_cardtext_4") }}</p>
                            </b-col>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

<!-- keyvisual in card
            <b-row align-h="center" class="mx-n5">
                <b-col class="p-md-5 p-sm-5 p-xs-5" xl="8" lg="12" md="12" sm="12">
                    <b-row class="text-center" cols="1" cols-sm="1" cols-md="1" cols-lg="1" cols-xl="1">
                        <b-col class="p-3 p-md-2 d-flex">
                            <b-col class="p-0" style="background-color: transparent; overflow: hidden; border-radius: 15px;">
                                <b-img class="" style="height: 40vh; width: 100%; object-fit: cover; object-position: 10% 30%; transform: scale(1.5);" src="images/bild.jpg" no-wrap center rounded />
                                <h1>get your diils.</h1>
                            </b-col>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
-->
        </b-container>
        <b-container>
            <b-row align-h="center" class="mx-n5 text-center" style="background-color: #DCD7D3; min-height: 200px;">
                <b-col class="mt-3 p-md-5 p-sm-5 p-xs-5" xl="7" lg="12" md="12" sm="12">
                    <b-row align-h="center" class="d-flex text-center">
                        <p align-h="center" class="px-3 text-center title_2">Sie werden begeistert sein!</p>
                        <p class="px-3 text-center" style="font-size: 1rem; font-weight: thin;">
                            Wir informieren Sie gerne über alle Funktionsmodule. Es gibt zahlreiche sehr interessante und erfolgreiche Anwendungsfälle und Praxisbeispiele. Sprechen Sie uns gerne an...
                        </p>
                    </b-row>
                </b-col>
            </b-row>

            <b-row align-h="center" class="mt-5"><h1>Ihre Ansprech&#173partner</h1></b-row>
            <br />
            <b-row align-h="center" class="mx-n5">
                <b-row class="justify-content-center">
                    <b-card title="André Grotstabel"
                        class="p-2 pb-4 m-3"    
                        style="width: 400px;"  
                        img-src="images/team/Grotstabel.jpg"         
                        img-top>
                        <p class="card-text">
                            Senior Manager<br/><br/>
                            <a href="mailto: andre.grotstabel@ebnerstolz.de">andre.grotstabel@ebnerstolz.de</a><br/>
                            Tel. +49 173 250 1031
                        </p>
                    </b-card>
                    <b-card title="Harald Göbl"
                        class="p-2 pb-4 m-3"
                        style="width: 400px;"    
                        img-src="images/team/Goebl.jpg"
                        img-top>
                        <p class="card-text">
                            Partner<br/><br/>
                            <a href="mailto: harald.goebl@ebnerstolz.de">harald.goebl@ebnerstolz.de</a><br/>
                            Tel. +49 163 510 1967
                        </p>
                    </b-card>
                    <b-card title="Arne Padberg"
                        class="p-2 pb-4 m-3"
                        style="width: 400px;"  
                        img-src="images/team/Padberg.jpg"  
                        img-top>
                        <p class="card-text">
                            Senior Manager<br/><br/>
                            <a href="mailto: arne.padberg@ebnerstolz.de">arne.padberg@ebnerstolz.de</a><br/>
                            Tel. +49 152 0905 4954 
                        </p>
                    </b-card>
                </b-row>
            </b-row>
        </b-container>

  
    </div>
  </template>
  
  <script>
      import { mapGetters } from 'vuex';
  
      export default {
          name: 'home',
          data() {
              return {};
          },
          computed: {
              ...mapGetters({
                  auth: 'auth/auth'
              })
          }
      }
  </script>
  