<template>
    <div class="home">
        <b-img class="" overlay style="height: 150px; width: 100%; object-fit: cover; object-position: 10% 30%; transform: scale(1.5);" src="images/bild.jpg" no-wrap center rounded />
        <br />
        <br />
        <br/>
        <br/>
        <b-row class="justify-content-center">
            <b-card-group deck>
                <b-card title="André Grotstabel"
                    class="p-2 pb-4"    
                    style="width: 550px;"           
                    img-top>
                    <p class="card-text">
                        Senior Manager<br/><br/>
                        <a href="mailto: andre.grotstabel@ebnerstolz.de">andre.grotstabel@ebnerstolz.de</a><br/>
                        Tel. +49 173 250 1031
                    </p>
                </b-card>
                <b-card title="Harald Göbl"
                    class="p-2 pb-4"
                    style="width: 550px;"    
                    img-top>
                    <p class="card-text">
                        Partner<br/><br/>
                        <a href="mailto: harald.goebl@ebnerstolz.de">harald.goebl@ebnerstolz.de</a><br/>
                        Tel. +49 174 176 7921
                    </p>
                </b-card>
            </b-card-group>
        </b-row>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>