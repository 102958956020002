<template>
    <nav class="navbar sticky-top shadow">

        <div class="container nav-container-settings">
            <b-row class="w-100">
                <b-col cols="auto" class="ml-md-auto p-3">
                    <router-link to="/" class="d-inline-block navbar-brand mx-auto">
                        <img src="images/diils_Logo_schwarz.png" height="150" class="d-inline-block align-middle" alt="">
                    </router-link>
                </b-col>
                <b-col cols="auto" class="p-3 ml-auto">
                    <template v-if="!loggedIn">
                        <a class="nav-item">
                            <router-link to="/login" class="nav-link">{{ $t("nav.login") }}</router-link>
                        </a>
                    </template>
                </b-col>
            </b-row>                   
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import localeHelper from '../helpers/localeHelper';
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            loggedIn: 'auth/loggedIn',
            currentUser: 'auth/user',
        }),
        loggedInUserEmail: function() {
            return this.currentUser ? this.currentUser.email : '';
        },
        supportedLocales() {
            return localeHelper.supportedLocales;
        }
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        }),
        toggleactive(){
            document.getElementById('sidebarMenu').classList.toggle('active');
            document.getElementById('app').classList.toggle('active');
        },
        smToggle() {
            var screenWidth = window.innerWidth;
            if (screenWidth < 768) {
                document.getElementById("sidebarMenu").classList.toggle('active');
            }
        },
        logoutCurrentUser () {
            this.logout().then(() => {
                return this.$router.push({path:'/login'});
            });
        }
    }
}
</script>
