<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">
        <template v-if="!this.editMode">
            {{ $t("questionnaire.questionnaire_new") }}
        </template>
        <template v-if="this.editMode">
            <router-link :to="'/app/questionnaires'" href="#">{{ $t("questionnaire.questionnaire_edit") }} </router-link>
            <v-icon style="height: 12px" class="mb-1" name="chevron-right" /> {{ questionnaire.name }}
        </template>
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-form @submit.prevent="onSubmit" v-show="doneLoading">
                <b-tabs>
                <b-tab :title="$t(`questionnaire.tab_basedata`)" active>
                    <b-card :title="$t(`questionnaire.card_basedata`)" class="mb-3 shadow">
                        <b-form-group :label="$t(`questionnaire.name`)" label-for="questionnaire-name">
                            <b-form-input id="questionnaire-name" type="text" required v-model="questionnaire.name" :placeholder="$t(`questionnaire.placeholder_name`)"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t(`questionnaire.request`)" label-for="questionnaire-request">
                            <b-form-select id="questionnaire-request" :placeholder="$t(`questionnaire.placeholder_request`)" v-model="questionnaire.request">
                                <option value="0">{{ $t("questionnaire.request_0") }}</option>
                                <option value="1">{{ $t("questionnaire.request_1") }}</option>
                                <option value="2">{{ $t("questionnaire.request_2") }}</option>
                                <option value="3">{{ $t("questionnaire.request_3") }}</option>
                                <option value="4">{{ $t("questionnaire.request_4") }}</option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group :label="$t(`questionnaire.requesttext`)" label-for="questionnaire-requesttext">
                            <b-form-textarea id="questionnaire-requesttext" type="text" v-model="questionnaire.requesttext" :placeholder="$t(`questionnaire.placeholder_requesttext`)" :rows="4"></b-form-textarea>
                        </b-form-group>
                        <b-form-group :label="$t(`questionnaire.year`)" label-for="questionnaire-year">
                            <b-form-input id="questionnaire-year" type="text" v-model="questionnaire.year" :placeholder="$t(`questionnaire.placeholder_year`)"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t(`questionnaire.version`)" label-for="questionnaire-version">
                            <b-form-input id="questionnaire-version" type="text" v-model="questionnaire.version" :placeholder="$t(`questionnaire.placeholder_version`)"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t(`questionnaire.status`)" label-for="questionnaire-status">
                            <b-form-select id="questionnaire-status" :placeholder="$t(`questionnaire.placeholder_status`)" v-model="questionnaire.status">
                                <option value="0">{{ $t("questionnaire.status_0") }}</option>
                                <option value="1">{{ $t("questionnaire.status_1") }}</option>
                                <option value="2">{{ $t("questionnaire.status_2") }}</option>
                            </b-form-select>
                        </b-form-group>
                    </b-card>
                </b-tab>

                <b-tab :title="$t(`questionnaire.tab_questions`)" :disabled="!editMode">
                    <b-card :title="$t(`questionnaire.card_questions`)" class="mb-3 shadow"> 
                        <b-button variant="primary" @click="newQuestion"><v-icon name="clipboard-list" /> {{ $t("questionnaire.btn_createquestion") }}</b-button>
                        <div class="table-scrollable">
                            <br/>
                            <b-table striped hover :items="questions" :fields="questionFields" sort-by="order">
                                <template #cell(required)="data">
                                    <template v-if="data.item.required">
                                        {{ $t("questionnaire.option_yes") }}
                                    </template>
                                    <template v-if="!data.item.required">
                                        {{ $t("questionnaire.option_no") }}
                                    </template>
                                </template>
                                <template #cell(type)="data">
                                    <span v-if="data.item.type == 1">
                                        {{ $t("questionnaire.option_integer") }}
                                    </span>
                                    <span v-if="data.item.type == 2">
                                        {{ $t("questionnaire.option_double") }}
                                    </span>
                                    <span v-if="data.item.type == 3">
                                        {{ $t("questionnaire.option_text") }}
                                    </span>
                                    <span v-if="data.item.type == 4">
                                        {{ $t("questionnaire.option_file") }}
                                    </span>
                                </template>
                                <template #cell(check)="data">
                                    <span v-if="data.item.selection != ''">
                                        {{ $t("questionnaire.option_list") }}
                                    </span>
                                    <span v-if="data.item.check_regex != ''">
                                        {{ $t("questionnaire.option_regex") }}
                                    </span>
                                    <span v-if="data.item.check_eval != ''">
                                        {{ $t("questionnaire.option_eval") }}
                                    </span>
                                </template>
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.order)"><v-icon name="angle-up" ></v-icon></b-link>
                                    <b-link class="m-1" href="#" v-show="data.index < questions.length-1" @click="moveItemDown(data.item.order)"><v-icon name="angle-down" ></v-icon></b-link>
                                    <b-link class="m-1" href="#" @click.prevent="editQuestion(data.item)"><v-icon name="edit"></v-icon></b-link>
                                    <b-link class="m-1" href="#" @click.prevent="requestDelete(data.item)"><v-icon name="trash"></v-icon></b-link>
                                </template>
                            </b-table>
                        </div>
                        <br/>
                        <b-button variant="primary" @click="newQuestion"><v-icon name="clipboard-list" /> {{ $t("questionnaire.btn_createquestion") }}</b-button>
                    </b-card>
                </b-tab>

                <b-tab :title="$t(`questionnaire.tab_usage`)" :disabled="!editMode">
                    <b-card :title="$t(`questionnaire.auctions`)" class="mb-3 shadow"> 
                        <div class="table-scrollable">
                            <b-table striped hover :items="questionnaire.related_auctions" :fields="relatedAuctionsTableFields">
                                <template #cell(start_date)="data">
                                    {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(end_date)="data">
                                    {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(organizer)="data">
                                    <template v-if="data.item.organizer">
                                        {{ data.item.organizer.name }} - {{data.item.organizer.company}}
                                    </template>
                                    <template v-if="!data.item.organizer">-</template>
                                </template>
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" :to="'/app/auktionen/view/' + data.item.id "><v-icon name="eye"></v-icon></b-link>
                                </template>
                                <template #cell(status)="data">
                                    {{getAuctionStatus(data.item.start_date, data.item.end_date)}}
                                </template>
                            </b-table>
                        </div>
                    </b-card>
                    
                    <b-card :title="$t(`questionnaire.callforbids`)" class="mb-3 shadow"> 
                        <div class="table-scrollable">
                            <b-table striped hover :items="questionnaire.related_callforbids" :fields="relatedCallForBidsTableFields">
                                <template #cell(start_date)="data">
                                    {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(end_date)="data">
                                    {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(organizer)="data">
                                    <template v-if="data.item.organizer">
                                        {{ data.item.organizer.name }} - {{data.item.organizer.company}}
                                    </template>
                                    <template v-if="!data.item.organizer">-</template>
                                </template>
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" :to="'/app/ausschreibung/view/' + data.item.id "><v-icon name="eye"></v-icon></b-link>
                                </template>
                                <template #cell(status)="data">
                                    {{getAuctionStatus(data.item.start_date, data.item.end_date)}}
                                </template>
                            </b-table>
                        </div>
                    </b-card>
                </b-tab>
                <b-tab :title="$t(`questionnaire.tab_preview`)" :disabled="!editMode">
                    <b-card class="mb-3 shadow">
                        <b-alert show v-if="questionnaire.requesttext" variant="info">
                            <b-form-group v-if="questionnaire.requesttext" label-for="questionnaire-requesttext">
                                <b-row class="mr-1" v-html="newlineToBr(questionnaire.requesttext)" id="questionnaire-requesttext"></b-row>
                            </b-form-group>
                        </b-alert>
                        <h3>{{questionnaire.name}}</h3>
                        <b-form @submit.prevent="submitForm()">
                            <template v-for="question in questionnaire.questions">
                                <b-form-group v-bind:key="question.id" :label="question.questiontext + (question.required ? '*' : '')">
                                    <template v-if="question.type === 1">
                                        <b-form-input type="number" :name="'question-'+question.id" />
                                    </template>
                                    <template v-if="question.type === 2">
                                        <b-form-input type="number" :name="'question-'+question.id" />
                                    </template>
                                    <template v-if="question.type === 3 && (question.selection === null || question.selection === '')">
                                        <b-form-input type="text" :name="'question-'+question.id" />
                                    </template>
                                    <template v-if="question.type === 3 && (question.selection !== null && question.selection !== '')">
                                        <b-form-select :name="'question-'+question.id">
                                            <template v-for="value in question.selection.split(';')">
                                                <option v-bind:key="'question-'+question.id+'-'+value" :value="value">{{value}}</option>
                                            </template>
                                        </b-form-select>
                                    </template>
                                    <template v-if="question.type === 4">
                                        <b-form-file :name="'question-'+question.id" placeholder="Datei wählen..."></b-form-file>
                                    </template>
                                </b-form-group>
                            </template>
                            <b-button disabled class="button-margin mt-4" type="submit" variant="primary"><b-icon icon="save" />{{ $t("questionnaire.btn_save") }}</b-button>
                            <b-button disabled class="button-margin mt-4" v-if="(questionnaire.request == 0 || questionnaire.request == 1)"><b-icon icon="download" />{{ $t("questionnaire.btn_download") }}</b-button>
                        </b-form>
                    </b-card>
                    <br />
                </b-tab>
            </b-tabs>
            <br />
            <b-button type="submit" variant="primary"><v-icon name="check" /> {{ $t("questionnaire.btn_save") }}</b-button>
        </b-form>
    </div>
    <b-modal ref="questionDialog" hide-footer size="lg" :title="$t(`questionnaire.modal_title`)">
        <b-container>
            <b-form @submit.prevent="onQuestionFormSubmit">
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_name`)" label-for="question-name">
                            <b-form-input id="question-name" v-model="currentQuestion.name" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_question`)" label-for="question-question">
                            <b-form-textarea id="question-question" v-model="currentQuestion.questiontext" class="overflow-auto mb-3" :no-resize="false" :rows="5"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_questionrequired`)" label-for="question-required">
                            <b-form-checkbox  id="question-required" v-model="currentQuestion.required" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_type`)" label-for="question-type">
                            <b-form-select id="question-type" v-model="currentQuestion.type" :options="questionTypes" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_selectionlist`)" label-for="question-selectionlist" :description="$t(`questionnaire.description_selectionlist`)">
                            <b-form-textarea id="question-selectionlist" v-model="currentQuestion.selection" class="overflow-auto" :no-resize="false" :rows="3"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_regex`)" label-for="question-checkregex">
                            <b-button v-b-toggle.help_regex pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                        </b-form-group>
                        <b-form-input id="question-checkregex" v-model="currentQuestion.check_regex" />
                        <b-sidebar right id="help_regex" :title="$t(`help.title_regex`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                            <div class="px-3 py-2">
                                <b-list-group>
                                    <b-list-group-item button class="d-flex align-items-center"><span v-html="$t('help.regex_1')" /><b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.regex_code_1`))"></b-icon></b-list-group-item>
                                    <b-list-group-item button class="d-flex align-items-center"><span v-html="$t('help.regex_2')" /><b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.regex_code_2`))"></b-icon></b-list-group-item>
                                    <b-list-group-item button class="d-flex align-items-center"><span v-html="$t('help.regex_3')" /><b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.regex_code_3`))"></b-icon></b-list-group-item>
                                </b-list-group>
                            </div>
                        </b-sidebar>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_eval`)" label-for="question-eval">
                            <b-form-input id="question-eval" v-model="currentQuestion.check_eval" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button type="submit"><v-icon name="check" /> {{ $t("questionnaire.btn_save") }} </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-container>
    </b-modal>
    <b-modal ref="confirmationModal" hide-footer :title="$t(`questionnaire.modal_question_delete`)">
        <div class="d-block text-center">
            <p >{{ $t("questionnaire.modal_note1") }}<strong>'{{itemToDelete.name}}'</strong>{{ $t("questionnaire.modal_note2") }}</p>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click.prevent="deleteQuestion">Löschen</b-button>
    </b-modal>
</div>
</template>
<script>
import questionnaireService from '../../../../services/QuestionnaireService';
import QuestionnaireService from '../../../../services/QuestionnaireService';

export default {
    name: 'questionnaire-createedit',
    data() {
        return {
            itemToDelete:{},
            editMode: false,
            doneLoading: false,
            questionnaire: {},
            questions: [],
            assignedAuctions: [],
            currentQuestion: {},
            currentQuestionEditMode: false,
            questionTypes: [
                {
                    value: 1,
                    text: this.$t(`questionnaire.option_integer`)
                },
                {
                    value: 2,
                    text: this.$t(`questionnaire.option_double`)
                },
                {
                    value: 3,
                    text: this.$t(`questionnaire.option_text`)
                },
                {
                    value: 4,
                    text: this.$t(`questionnaire.option_file`)
                }
            ],
        }
    },
    created() {
        if(this.$route.params.id) {
            this.editMode = true;
            this.$store.commit('loading/setLoading', true);
            questionnaireService.fetch(this.$route.params.id)
                .then(response => {
                    this.doneLoading = true;
                    this.questionnaire = response.data;

                    return this.fetchQuestions(this.$route.params.id)
                        .then(() => {
                            return questionnaireService.fetchAssignedAuctions(this.$route.params.id)
                                .then(response => {
                                    this.assignedAuctions = response.data;
                                    this.$store.commit('loading/setLoading', false);
                                })
                        });
                })
        }else {
            this.doneLoading = true;
        }
    },
    methods: {
        onSubmit() {
            this.$store.commit('loading/setLoading', true);

            if(!this.editMode) {
                questionnaireService.create(this.questionnaire.name)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(response.success === undefined) {
                            this.questionnaire = response.data;
                            this.editMode = true;

                            this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notify_success_title"),
                                type: 'success',
                                text: this.$t("questionnaire.notify_success_message")
                            });
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notify_error_title"),
                                type: 'error',
                                text: response.message
                            });
                        }
                    });
            }else {
                this.questionnaire.questions = this.questions;
                questionnaireService.update(this.questionnaire)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(response.success === undefined) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notifiy_update_title"),
                                type: 'success',
                                text: this.$t("questionnaire.notifiy_update_message")
                            });
                            return this.fetchQuestions();
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notifiy_update_error"),
                                type: 'error',
                                text: response.message
                            });
                        }
                    });
            }
        },
        newQuestion() {
            this.currentQuestion = {};
            this.$refs.questionDialog.show();
        },
        editQuestion(question) {
            this.currentQuestion = JSON.parse(JSON.stringify(question));
            this.currentQuestionEditMode = true;
            this.$refs.questionDialog.show();
        },
        onQuestionFormSubmit() {
            if(!this.currentQuestionEditMode) {
                questionnaireService.createQuestion(this.currentQuestion, this.questionnaire.id)
                    .then(response => {
                        this.$refs.questionDialog.hide();
                        this.currentQuestion = {};
                        return questionnaireService.fetchQuestions(this.questionnaire.id)
                            .then(questionsResponse => {
                                this.questions = questionsResponse.data;
                            });
                    });
            }else {
                questionnaireService.updateQuestion(this.currentQuestion)
                    .then(response => {
                        this.$refs.questionDialog.hide();
                        this.currentQuestion = {};
                        this.currentQuestionEditMode = false;
                        this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notifiy_updatequestion_title"),
                                type: 'success',
                                text: this.$t("questionnaire.notifiy_updatequestion_message")
                            });
                        this.$store.commit('loading/setLoading', true);
                        return this.fetchQuestions()
                            .then(() => {
                                this.$store.commit('loading/setLoading', false);
                            });
                    })
            }
        },
        findItemByOrder(order) {
            for(var i=0; i< this.questions.length; i++) {
                if(this.questions[i].order == order) {
                    return this.questions[i];
                }
            }
        },
        moveItemUp(order) {
            var itemToMoveUp = this.findItemByOrder(order);
            var itemToMoveDown = this.findItemByOrder(order-1);

            itemToMoveUp.order = order-1;
            itemToMoveDown.order = order
        },
        moveItemDown(order) {
            var itemToMoveDown = this.findItemByOrder(order);
            var itemToMoveUp = this.findItemByOrder(order+1);

            itemToMoveDown.order = order+1
            itemToMoveUp.order = order;
        },
        fetchQuestions() {
            return QuestionnaireService.fetchQuestions(this.questionnaire.id)
                .then(response => {
                    this.questions = response.data;
                });
        },
        requestDelete(item) {
            this.itemToDelete = item;
            this.$refs.confirmationModal.show();
        },
        deleteQuestion() {
            QuestionnaireService.deleteQuestion(this.questionnaire.id,this.itemToDelete.id)
                .then(response => {
                    this.$refs.confirmationModal.hide();
                    this.itemToDelete = {};
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.notifiy_deletequestion_title"),
                            type: 'success',
                            text: this.$t("questionnaire.notifiy_deletequestion_message")
                        });

                        return this.fetchQuestions();

                    }else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.notifiy_deletequestion_error"),
                            type: 'error',
                            text: this.$t("questionnaire.notifiy_deletequestion_errormessage")
                        });
                    }
                });
        },
        newlineToBr: function (value) {
            return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        }
    },
    computed: {
        questionFields() {
            return [
                {
                    key: 'name',
                    label: this.$t(`questionnaire.label_name`)
                },
                {
                    key: 'questiontext',
                    label: this.$t(`questionnaire.label_question`)
                },
                {
                    key: 'required',
                    label: this.$t(`questionnaire.label_questionrequired`)
                },
                {
                    key: 'type',
                    label: this.$t(`questionnaire.label_type`)
                },
                {
                    key: 'check',
                    label: this.$t(`questionnaire.label_check`)
                },
                {
                    key: 'actions',
                    label: this.$t(`questionnaire.label_actions`)
                }
            ];
        },
        relatedAuctionsTableFields() {
            return [
                {
                    key: 'title',
                    label: this.$t(`auction_list.auction.column_title`),
                    sortable: true
                },
                {
                    key: 'organizer',
                    label: this.$t(`auction_list.auction.column_organizer`),
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: this.$t(`auction_list.auction.column_start`),
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: this.$t(`auction_list.auction.column_end`),
                    sortable: true
                },
                {
                    key: 'actions',
                    label: this.$t(`auction_list.auction.column_actions`)
                }
            ];
        },
        relatedCallForBidsTableFields() {
            return [
                {
                    key: 'title',
                    label: this.$t(`auction_list.tender.column_title`),
                    sortable: true
                },
                {
                    key: 'organizer',
                    label: this.$t(`auction_list.tender.column_organizer`),
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: this.$t(`auction_list.tender.column_start`),
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: this.$t(`auction_list.tender.column_end`),
                    sortable: true
                },
                {
                    key: 'actions',
                    label: this.$t(`auction_list.tender.column_actions`)
                }
            ];
        },
    }
};
</script>
    