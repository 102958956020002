<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #f4f5fa">Vertragsmanagement</div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-card title="Vertragsübersicht" class="mb-3 shadow">
                <b-button variant="primary" to="/app/contract/new" class="button-margin" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />Vertrag anlegen</b-button>
                <b-button variant="primary" class="button-margin" @click.prevent="showImport" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="file-earmark-arrow-down" />Importieren</b-button>
                <br />
                <b-form-group horizontal label="Suche" class="mb-0">
                <b-input-group>
                    <b-form-input v-model="searchFilter" placeholder="Volltextsuche" />
                    <b-input-group-append>
                    <b-button :disabled="!searchFilter" @click="searchFilter = ''">Leeren</b-button>
                    </b-input-group-append>
                </b-input-group>
                </b-form-group>
                <br />
                <div class="table-scrollable">
                <b-table bordered striped hover :items="contracts" :fields="contractFields" :filter="searchFilter">
                    <template #cell(actions)="data">
                        <b-link class="m-1" href="#" :to="'/app/contract/view/' + data.item.id"><v-icon name="eye"></v-icon></b-link>
                        <b-link class="m-1" href="#" :to="'/app/contract/edit/' + data.item.id" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><v-icon name="edit"></v-icon></b-link>
                        <b-link class="m-1" href="#" @click="confirmDelete(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><v-icon name="trash"></v-icon></b-link>
                    </template>
                </b-table>
                <br />
                </div>
                <br />
                <b-button variant="primary" class="button-margin" to="/app/contract/new" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />Vertrag anlegen</b-button>
                <b-button variant="primary" class="button-margin" @click.prevent="showImport" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="file-earmark-arrow-down" />Importieren</b-button>
            </b-card>

            <b-modal ref="confirmationModal" hide-footer :title="'Vertrag löschen'">
                <div class="d-block text-center">
                    <p v-if="contractContext">Möchten Sie den Vertrag <strong>'{{contractContext.contract_title}}'</strong> wirklich löschen?</p>
                </div>
                <b-button class="mt-3" variant="outline-danger" block @click="executeDelete"><b-icon icon="file-earmark-x" />Löschen</b-button>
            </b-modal>

            <b-modal ref="importModal" hide-footer :title="'Vertrag importieren'">
                <div class="d-block text-center">
                    Bitte beachten Sie: Um einen Datensatz bzw. Vertragsparameter zu aktualisieren, müssen Sie das Schlüsselfeld (Id) in der Exceldatei passend zu der Id des Einzelvertrags eintragen.
                </div>
                <b-form-file ref="excelFileInput" v-model="excelFile" :state="Boolean(excelFile)" placeholder="Vertragsstammdaten wählen..."></b-form-file>
                <b-button class="mt-3" block @click="importExcelFile"><b-icon icon="file-earmark-arrow-down" />Importieren</b-button>
            </b-modal>
        </div>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex';
    import importService from '../../../../services/ImportService';
    import ContractService from '../../../../services/ContractService';
    import { dateHelper } from '../../../mixins/dateHelper';

    export default {
        name: 'contract-list',
        mixins: [dateHelper],
        data() {
            return {
                archived:'',
                excelFile: null,
                searchFilter: '',
                contractContext: null,
            }
        },
        created() {
        this.listContracts();
        },
        watch: {
        '$route': 'fetchContracts'
        },
        methods: {
            ...mapActions({
                listContracts: 'contracts/fetchContracts',
                deleteContract: 'contracts/delete',
                fetchSelectionLists: 'contracts/fetchSelectionLists'
            }),
            confirmDelete(contract) {
                this.contractContext = contract;
                this.$refs.confirmationModal.show();
            },
            executeDelete() {
                this.$refs.confirmationModal.hide();
                this.deleteContract(this.contractContext.id)
                    .then(response => {
                        if (response.status == 200) {
                            this.$notify({
                                group: 'global',
                                title: 'Vertrag gelöscht.',
                                type: 'success',
                                text: 'Der Vertrag wurde erfolgreich gelöscht.'
                            })
                        } else {
                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Löschen des Vertrags',
                                type: 'error',
                                text: response.data.message
                            });
                        }
                        this.contractContext = null;
                    });
            },
            showImport() {
                this.excelFile = null;
                this.$refs.excelFileInput.reset();
                this.$refs.importModal.show();
            },
            importExcelFile() {
                importService.importContract(this.excelFile)
                    .then(response => {
                        if(response.data.success) {
                            this.$refs.excelFileInput.reset();
                            this.$refs.importModal.hide();
                            this.fetchList();

                            this.$notify({
                                group: 'global',
                                title: 'Vertrag importiert.',
                                type: 'success',
                                text: 'Der Vertrag wurde erfolgreich importiert.'
                            });
                        }
                    })
                    .catch(error => {
                    });

            },
            reloadList(value) {
                this.fetchList(value)
            },
        },
        computed: {
            ...mapGetters({
                contracts: 'contracts/contracts',
                hasRole: 'auth/hasRole',
                appendixTypes: 'contracts/appendixTypes',
                contractConfidentialities: 'contracts/contractConfidentialities',
                contractExtensionTypes: 'contracts/contractExtensionTypes',
                contractOccasions: 'contracts/contractOccasions',
                contractRiskLevels: 'contracts/contractRiskLevels',
                contractStates: 'contracts/contractStates',
                contractStrategicImportances: 'contracts/contractStrategicImportances',
                contractSubjects: 'contracts/contractSubjects',
                contractTerminationForms: 'contracts/contractTerminationForms',
                contractTypes: 'contracts/contractTypes',
                contractValueClusters: 'contracts/contractValueClusters',
                resubmissionReasons: 'contracts/resubmissionReasons',
                resubmissionStates: 'contracts/resubmissionStates',
            }),
            contractFields() {
                return [
                    {   key: 'actions',                        label: 'Aktionen'                   },
                    {   key: 'id',                             label: 'Id'                         },
                    {   key: 'contract_number',                label: 'Vertragsnummer'             },
                    {   key: 'contract_extern_partner',        label: 'Vertragspartner'            },
                    {   key: 'contract_title',                 label: 'Vertragstitel'              },
                    {   key: 'contract_startdate',             label: 'Vertragsbeginn'             , formatter: this.dateFormatter },
                    {   key: 'contract_enddate',               label: 'Vertragsende'               , formatter: this.dateFormatter , sortable: true   },
                    {   key: 'contract_termination_date',      label: 'Kündigungstermin'           , formatter: this.dateFormatter , sortable: true   },
                    {   key: 'contract_period_of_notice',      label: 'Kündigungsfrist'            },
                    {   key: 'contract_terminated_on',         label: 'gekündigt zum'              , formatter: this.dateFormatter },
                    {   key: 'contract_terminated_at',         label: 'gekündigt am'               , formatter: this.dateFormatter },
                    {   key: 'contract_termination_by',        label: 'gekündigt durch'            },
                    {   key: 'contract_termination_form',      label: 'Kündigungsform'             },
                    {   key: 'contract_extension_type',        label: 'Verlängerungsart'           , sortable: true   },
                    {   key: 'contract_extension_period',      label: 'Verlängerungsperiode'       },
                    {   key: 'contract_reviewed',              label: 'Vertrag geprüft'            },
                    {   key: 'contract_reviewed_by',           label: 'geprüft durch'              },
                    {   key: 'contract_approved',              label: 'Vertrag genehmigt'          },
                    {   key: 'contract_approved_by_1',         label: 'genehmigt durch Stufe 1'    },
                    {   key: 'contract_approved_by_2',         label: 'genehmigt durch Stufe 2'    },
                    {   key: 'contract_approved_by_3',         label: 'genehmigt durch Stufe 3'    },
                    {   key: 'payment_conditions',             label: 'Zahlungskonditionen'        },
                    {   key: 'delivery_conditions',            label: 'Lieferbedingungen'          },
                    {   key: 'contract_type',                  label: 'Vertragstyp'                , sortable: true   },
                    {   key: 'contract_confidentiality',       label: 'Vertraulichkeit'            , sortable: true   },
                    {   key: 'contract_risk_level',            label: 'Risikostufe'                , sortable: true   },
                    {   key: 'contract_strategic_importance',  label: 'Strategische Relevanz'      , sortable: true   },
                    {   key: 'contract_value_cluster',         label: 'Wertcluster'                },
                    {   key: 'contract_value',                 label: 'Vertragswert'               , sortable: true   },
                    {   key: 'contract_department',            label: 'Abteilung'                  },
                    {   key: 'contract_subject',               label: 'Vertragssubjekt'            },
                    {   key: 'contract_occasion',              label: 'Vertragsanlass'             },
                    {   key: 'contract_notes',                 label: 'Bemerkungen'                },
                    {   key: 'contract_keywords',              label: 'Schalgworte'                },
                    {   key: 'contract_physical_location',     label: 'Ablageort'                  },
                    {   key: 'auction_id',                     label: 'zugeordnete Vergabe'        },
                    {   key: 'contract_state',                 label: 'Vertragsstatus'             , sortable: true   },
                    {   key: 'contract_currency',              label: 'Währung'                    },
                    {   key: 'contract_filename',              label: 'Dateibezeichnung'           },
                    {   key: 'contract_mimetype',              label: 'Dateityp'                   },
                    {   key: 'contract_superordinated_to',     label: 'Vertrag übergeordnet zu'    },
                    {   key: 'contract_subordinated_to',       label: 'Vertrag untergeordnet zu'   },
                    {   key: 'contract_followup_to',           label: 'Folgevertrag zu'            },
                    {   key: 'contract_previous_to',           label: 'Vorgängervertrag zu'        },
                    {   key: 'company',                        label: 'Gesellschaft intern'        },
                    {   key: 'division',                       label: 'Bereich intern'             },
                    {   key: 'purchasing_organisation',        label: 'Einkaufsorganisation'       },
                    {   key: 'purchasing_group',               label: 'Einkaufsgruppe'             },
                    {   key: 'company_location',               label: 'Standort'                   },
                    {   key: 'profit_center',                  label: 'Profit Center'              },
                    {   key: 'cost_center',                    label: 'Kostenstelle'               },
                    {   key: 'equipment_number',               label: 'Equipment Nummer'           },
                    {   key: 'investment_number',              label: 'Investitionsnummer'         },
                    {   key: 'contract_extern_partner_role',   label: 'Partnerrolle extern'        },
                    {   key: 'contract_extern_partner_number', label: 'Vertragspartner Nummer'     },
                    {   key: 'contract_extern_responsible',    label: 'Verantwortlich extern'      },
                    {   key: 'contract_extern_contact_person', label: 'Kontaktperson extern'       },
                    {   key: 'contract_extern_adress',         label: 'Kontakt Adresse'            },
                    {   key: 'contract_extern_email',          label: 'Kontakt Email'              },
                    {   key: 'contract_intern_management',     label: 'Verantwortlich intern GF'   , sortable: true   },
                    {   key: 'contract_intern_responsible',    label: 'Verantwortlich intern'      , sortable: true   },
                    {   key: 'contract_intern_contact_person', label: 'Ansprechpartner intern'     }
                ];
            },

        }
    };
</script>
