<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">
        {{ $t("user.header_profile") }} <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ user.name }}
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-tabs>
            <b-tab :title="$t(`user.tab_editprofile`)" active>
                <b-card :title="$t(`user.card_editprofile`)" class="mb-3 shadow">
                    <b-form @submit.prevent="onSubmitUserInfo" v-show="doneLoading">
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t(`user.name`)" label-for="user-name">
                                    <b-form-input id="user-name" type="text" v-model="user.name" :state="!$v.user.name.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.password`)" label-for="user-password">
                                        <b-form-input id="user-password" type="password" v-model="user.password" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.passwordrepeat`)" label-for="user-password-repeat">
                                        <b-form-input id="user-password-repeat" type="password" v-model="user.password_repeat" :state="!$v.user.password_repeat.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.contact`)" label-for="user-contact">
                                    <b-form-input id="user-contact" type="text" v-model="user.contact" :state="!$v.user.contact.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.phone`)" label-for="user-phone">
                                    <b-form-input id="user-phone" type="text" v-model="user.phone" :state="!$v.user.phone.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.email`)" label-for="user-email">
                                    <b-form-input id="user-email" type="email" v-model="user.email" :state="!$v.user.email.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.businessunit`)" label-for="user-business-unit">
                                    <b-form-input id="user-business-unit" type="text" v-model="user.business_unit" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-checkbox v-if="!hideAgreements" v-model="user.accepted_terms_of_service" class="terms-text">
                                    {{ $t("user.accept_termsofservice_1") }}<a href="#" @click.prevent="showTermsOfService">{{ $t("user.accept_termsofservice_2") }}</a>.
                                </b-form-checkbox>
                                <b-form-checkbox v-if="!hideAgreements" v-model="user.accepted_dsgvo" class="terms-text">
                                    {{ $t("user.accept_dsgvo") }}
                                </b-form-checkbox>

                            </b-col>
                            <b-col>
                                <b-form-group :label="$t(`user.company`)" label-for="user-company">
                                    <b-form-input id="user-company" type="text" v-model="user.company" :state="!$v.user.company.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logo`)" label-for="user-logo">
                                    <template v-if="user.logo">
                                        <b-img :src="user.logo" class="preview-image"></b-img>
                                    </template>
                                    <p class="text-primary" v-if="!user.logo"><strong>{{ $t("user.not_available") }}</strong></p>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logoupload`)" label-for="user-logo">
                                    <b-form-file id="user-logo" v-model="file" placeholder="Logo auswählen..." style="background-color: #fdfdfd"></b-form-file>
                                </b-form-group>

                                <b-form-group :label="$t(`user.address`)" label-for="user-address">
                                    <b-form-textarea id="user-address" type="text" v-model="user.address" :state="!$v.user.address.$error ? null : false" :no-resize="true" :rows="3" :max-rows="3" style="background-color: #fdfdfd"></b-form-textarea>
                                </b-form-group>

                                <b-form-group :label="$t(`user.employees`)" label-for="user-employees">
                                    <b-form-input id="user-employees" type="text" v-model="user.employee_count" :state="!$v.user.employee_count.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.sales`)" label-for="user-sales">
                                    <b-form-input id="user-sales" type="text" v-model="user.sales" :state="!$v.user.sales.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button type="submit" variant="primary"><b-icon icon="save" />{{ $t("user.btn_save") }}</b-button>
                    </b-form>
                </b-card>
            </b-tab>
            <b-tab :title="$t(`user.tab_selfdisclosure`)">
                <b-container>
                    <template v-for="questionnaire in questionnaires">
                        <b-row v-bind:key="questionnaire.id">
                            <b-col>
                                <QuestionnaireComponent :questionnaire="questionnaire" :answers="answers" />
                            </b-col>
                        </b-row>
                    </template>
                </b-container>
            </b-tab>

            <b-tab title="Lieferkettengesetz">
                <b-row>
                    <b-col cols="12" lg="3">
                        <b-list-group class="mb-2" v-b-scrollspy:listgroup-ex>
                            <b-list-group-item href="#list-item-1">A. Stammdaten</b-list-group-item>
                            <b-list-group-item href="#list-item-2">B. Berichterstattung und Grundsatzerklärung</b-list-group-item>
                            <b-list-group-item href="#list-item-3">C. Zertifikate und Initiativen</b-list-group-item>
                            <b-list-group-item href="#list-item-4">D. Risikomanagement</b-list-group-item>
                            <b-list-group-item href="#list-item-5">E. Steuerung der Vorlieferanten</b-list-group-item>
                            <b-list-group-item href="#list-item-6">F. Maßnahmen zur Risikoprävention</b-list-group-item>
                        </b-list-group>
                    </b-col>
            
                    <b-col cols="12" lg="9">
                        <div id="listgroup-ex" style="position:relative; overflow-y:auto; overflow-x:hidden; scrollbar-width:thin; height:700px">
                            <b-card id="list-item-1" title="A. Stammdaten" class="mb-3 shadow">
                                <b-row><b-col>
                                    <b-form-group label="Registernummer" label-for="Registernummer">
                                        <b-form-input id="Registernummer" placeholder="..."></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Namen der vertretungsberechtigten Organe (Geschäftsführer/Vorstand)" label-for="organe">
                                        <b-form-input id="name_1" placeholder=""></b-form-input>
                                        <b-form-input id="name_2" placeholder=""></b-form-input>
                                        <b-form-input id="name_3" placeholder=""></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Standorte" label-for="location">
                                        <b-form-input id="standort_1" placeholder=""></b-form-input>
                                        <b-form-input id="standort_2" placeholder=""></b-form-input>
                                        <b-form-input id="standort_3" placeholder=""></b-form-input>
                                        <b-form-input id="standort_4" placeholder=""></b-form-input>
                                        <b-form-input id="standort_5" placeholder=""></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Produktgruppe 1" label-for="productgroup1">
                                        <b-form-input id="product_11" placeholder=""></b-form-input>
                                        <b-form-input id="product_12" placeholder=""></b-form-input>
                                        <b-form-input id="product_13" placeholder=""></b-form-input>
                                        <b-form-input id="product_14" placeholder=""></b-form-input>
                                        <b-form-input id="product_15" placeholder=""></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Produktgruppe 2" label-for="productgroup2">
                                        <b-form-input id="product_21" placeholder=""></b-form-input>
                                        <b-form-input id="product_22" placeholder=""></b-form-input>
                                        <b-form-input id="product_23" placeholder=""></b-form-input>
                                        <b-form-input id="product_24" placeholder=""></b-form-input>
                                        <b-form-input id="product_25" placeholder=""></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Produktgruppe 3" label-for="productgroup3">
                                        <b-form-input id="product_31" placeholder=""></b-form-input>
                                        <b-form-input id="product_32" placeholder=""></b-form-input>
                                        <b-form-input id="product_33" placeholder=""></b-form-input>
                                        <b-form-input id="product_34" placeholder=""></b-form-input>
                                        <b-form-input id="product_35" placeholder=""></b-form-input>
                                    </b-form-group>

                                </b-col></b-row>
                            </b-card>

                            <b-card id="list-item-2" title="B. Berichterstattung und Grundsatzerklärung" class="mb-3 shadow">
                                <b-form-group label="Unterliegt Ihr Haus der Verpflichtung einer gesetzlichen Nachhaltigkeitsberichterstattung?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein</option>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group label="Oder veröffentlicht Ihr Haus einen freiwilligen Nachhaltigkeitsbericht?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein</option>
                                        <option value="3">Die Erstellung eines Nachhaltigkeitsberichtes ist geplant</option>
                                    </b-form-select>
                                </b-form-group>
                                
                                <b-form-group label="Verfügt Ihr Unternehmen über eine Grundsatzerklärung zu Menschen- und Umweltrechten?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">ja</option>
                                        <option value="2">nein</option>
                                        <option value="3">Die Erstellung einer Grundsatzerklärung ist geplant</option>
                                    </b-form-select>
                                </b-form-group>
                                
                            </b-card>

                            <b-card id="list-item-3" title="C. Zertifikate und Initiativen" class="mb-3 shadow">
                                <b-form-group label="1. Verfügen Sie bereits über einschlägige Zertifizierungssysteme, wie z. B. ISO 14001, ISO 9001, EMAS, OHSAS 18001, ISO 27000?" label-for="...">
                                    <b-form-checkbox size="sm" class="ml-4">ISO 14001</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">ISO 9001</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">EMAS</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">OHSAS 18001</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">ISO 27000</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4 mt-2">weitere Zertifizierungssysteme</b-form-checkbox>
                                    <b-form-input size="sm" class="ml-4" id="" placeholder="Bitte geben Sie hier weitere Ihrer Zertifizierungssysteme an ..."></b-form-input>
                                </b-form-group>
                                <b-form-group label="2. Ist Ihr Haus im Bereich Risikomanagement zertifiziert (RMS, z.B. nach IDW PS 981) oder im Bereich Compliance Management (CMS, z.B. nach IDW 980) bzw. Interne Kontrollsysteme (IKS, z.B. nach IDW PS 982)?" label-for="...">
                                    <b-form-checkbox size="sm" class="ml-4">Risikomanagement-System nach IDW PS 981</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">Compliancemanagement-System nach IDW 980</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">Internes Kontrollsystem nach IDW PS 982</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4 mt-2">Einsatz sonstiger Systeme</b-form-checkbox>
                                    <b-form-input size="sm" class="ml-4" id="" placeholder="Bitte geben Sie hier Informationen zu sonstigen Ihrer Risikomanagement-Systeme an ..."></b-form-input>
                                </b-form-group>
                                <b-form-group label="3. Bitte laden Sie hier Ihre aktuellen Zertifikate hoch" label-for="...">
                                    <b-form-file size="sm" placeholder="Dokument hochladen..." id="..."></b-form-file>
                                </b-form-group>
                                <b-form-group label="4. Sind Sie Mitglied einer einschlägigen Initiative, wie z. B. EcoVadis, CDP (Carbon Disclosure Project), UNGC (UN Global Compact), WBCSD (World Business Council for Sustainable Development), CSR Europe, Globale Wirtschaftsinitiative für Menschenrechte (GBI)?" label-for="...">
                                    <b-form-checkbox size="sm" class="ml-4">EcoVadis</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">CDP (Carbon Disclosure Project)</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">UNGC (UN Global Compact)</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">WBCSD (World Business Council for Sustainable Development)</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">CSR Europe</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4">Globale Wirtschaftsinitiative für Menschenrechte (GBI)</b-form-checkbox>
                                    <b-form-checkbox size="sm" class="ml-4 mt-2">Mitglied weiterer Initiativen</b-form-checkbox>
                                    <b-form-input size="sm" class="ml-4" id="" placeholder="Bitte geben Sie hier weiterführende Informationen zu Ihren Mitgliedschaften bei sonstigen Initiativen an ..."></b-form-input>
                                </b-form-group>
                            </b-card>

                            <b-card id="list-item-4" title="D. Risikomanagement" class="mb-3 shadow">
                                <b-form-group label="1. Führen Sie regelmäßig eine Risikoanalyse im Hinblick auf Einhaltung von Menschenrechten und Umweltvorgaben in Ihrem Unternehmen und bei Ihren Lieferanten durch?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja, im eigenen Unternehmen</option>
                                        <option value="2">Ja, im eigenen Unternehmen und bei Vorlieferanten</option>
                                        <option value="3">Nein</option>
                                    </b-form-select>
                                </b-form-group>

                                <b-form-group label="2. Auditieren Sie Ihre Lieferanten hierzu, z.B. im Rahmen des QM-Prozesses oder der Lieferantenbewertung?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein</option>
                                        <option value="3">Nein, aber eine Auditierung des Risikomanagemnt-Systems unserer Vorlieferanten ist geplant</option>
                                    </b-form-select>
                                </b-form-group>
                                
                                <b-form-group label="3. Haben Sie einen Risikomanagementprozess in Ihrem Unternehmen/Ihrer Unternehmensgruppe etabliert und werden in diesem Zusammenhang Risiken regelmäßig identifiziert, bewertet und gesteuert?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein, aber wir beabsichtigen einen Risikomanagementprozess einzuführen</option>
                                        <option value="3">Nein</option>
                                    </b-form-select>
                                </b-form-group>

                                <b-form-group label="4. Falls ja, werden umweltbezogen und menschenrechtliche Risiken in das Risikomanagementsystem (RMS) auch einbezogen?" label-for="...">
                                    <b-form-select id="report" placeholder="">
                                        <option value="1">Ja</option>
                                        <option value="2">Nein</option>
                                    </b-form-select>
                                </b-form-group>
                            </b-card>

                            <b-card id="list-item-5" title="E. Steuerung der Vorlieferanten" class="mb-3 shadow">
                                
                            </b-card>

                            <b-card id="list-item-6" title="F. Maßnahmen zur Risikoprävention" class="mb-3 shadow">
                                
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
    </div>
    <b-modal id="termsOfService" ref="termsOfService" :title="$t('profile.termsofservicetitle')" size="lg" :cancelDisabled="true">
        <template slot="modal-footer">
            <b-button @click="$refs.termsOfService.hide()"><b-icon icon="check2" />{{ $t("user.modal_btn_ok") }}</b-button>
        </template>
        <TermsOfServiceComponent />
    </b-modal>
</div>
</template>
<script>
import userService from '../../../../services/UserService';
import { helpers, required, email, sameAs, numeric } from 'vuelidate/lib/validators';
import TermsOfServiceComponent from '../../content/TermsOfService';
import QuestionnaireService from '../../../../services/QuestionnaireService';
import DownloadService from '../../../../services/DownloadService';
import QuestionnaireComponent from './Questionnaire';

export default {
    name: 'profile',
    components: {
        TermsOfServiceComponent,
        QuestionnaireComponent
    },
    data() {
        return {
            doneLoading: false,
            hideAgreements: false,
            questionnaires: [],
            answers: {},
            user: {},
            file: {}
        }
    },
    validations() {
        let validations = {
            answers: {}
        };

        this.questionnaires.forEach(q => {
            q.questions.forEach(question => {
                validations['answers'][question.id] = {};

                if(question.required) {
                    validations['answers'][question.id]['required'] = required;
                }

                if(question.check_regex) {
                    const customValidator = (value) => {
                        if(value === undefined || value === null) {
                            return true;
                        }

                        return new RegExp(question.check_regex).test(value);
                    }

                    validations['answers'][question.id]['regex'] = customValidator;
                }

                if(question.check_eval) {
                     const customValidator = (value) => {
                        if(value === undefined || value === null || value === '') {
                            return true;
                        }

                        return eval(''+value+question.check_eval);
                    }

                    validations['answers'][question.id]['eval'] = customValidator;
                }
            });
        });

        return validations;
    },
    created() {
        this.loadUserProfile();
    },
    validations: {
        user: {
            name: {
                required
            },
            password_repeat: {
                sameAsPassword: sameAs('password')
            },
            contact: {
                required
            },
            phone: {
                required
            },
            company: {
                required
            },
            address: {
                required
            },
            employee_count: {
                required, numeric
            },
            sales: {
                required
            },
            email: {
                required,
                email
            }
        }
    },
    methods: {
        loadUserProfile() {
            this.$store.commit('loading/setLoading', true);

            userService.fetchProfile()
                .then(userResponse => {
                  this.user = userResponse.data;

                  if(this.user.accepted_dsgvo && this.user.accepted_terms_of_service) {
                    this.hideAgreements = true;
                  }

                  return QuestionnaireService.fetchForMe()
                      .then(response => {
                        this.questionnaires = response.data;

                        return QuestionnaireService.fetchMyAnswers()
                            .then(answers => {
                              this.$store.commit('loading/setLoading', false);
                              this.answers = answers.data;
                              this.doneLoading = true;
                            });
                      });
                })
        },
        submitForm(questionnaireId) {
            let questionnaire;
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }
            this.questionnaires.some(qn => {
                if(qn.id === questionnaireId) {
                    questionnaire = qn;
                    return true;
                }
            })

            let values = {};

            questionnaire.questions.forEach(q => {
                values[q.id] = this.answers[q.id];
            });

            QuestionnaireService.saveQuestions(questionnaireId, values)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_questionnaire_save"),
                            type: 'success',
                            text: this.$t("user.notify_questionnaire_message")
                        });
                    }
                });
        },
        onSubmitUserInfo() {
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }
            userService.updateProfile(this.user, this.file)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_profile_save"),
                            type: 'success',
                            text: this.$t("user.notify_profile_message")
                        });
                        // TODO: Does not work yet
                        this.loadUserProfile();
                    } else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_profile_error"),
                            type: 'error',
                            text: this.$t("user.notify_profile_errormessage")
                        });
                    }
                });
        },
        showTermsOfService() {
            this.$refs.termsOfService.show();
        },
        downloadQuestionnaire(questionnaireId) {
            QuestionnaireService.downloadQuestionnaire(questionnaireId)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'fragebogen.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        downloadFile(downloadKey, filename, mimetype) {
            QuestionnaireService.downloadFile(downloadKey)
                .then(response => {
                    DownloadService.processDownload(response, filename, mimetype);
                });
        }
    },
    computed: {
        fieldState() {
            return quesionId => this.$v['question-'+quesionId].$error ? 'valid' : false;
        }
    }
};
</script>
