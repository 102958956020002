<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">
        {{ $t("user.header") }}
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-card :title="$t(`user.card_user`)" class="mb-3 shadow">
            <b-button v-b-toggle.help_user pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
            <b-button variant="primary" class="button-margin" to="/app/user/new"><b-icon icon="person-plus" />{{ $t("user.btn_usernew") }}</b-button>
            <b-button variant="primary" class="button-margin" @click.prevent="showImport">
                <b-iconstack>
                    <b-icon stacked icon="person"></b-icon>
                    <b-icon stacked icon="arrow-down" shift-h="7" shift-v="3" scale="0.7"></b-icon>
                </b-iconstack>
                {{ $t("user.btn_userimport") }}
            </b-button>
            <b-sidebar right id="help_user" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                <div class="px-3 py-2">
                    <b-list-group>
                        <b-list-group-item class="user-select-all">{{ $t("help.user_1") }}</b-list-group-item>
                        <b-list-group-item class="user-select-all">{{ $t("help.user_2") }}</b-list-group-item>
                        <b-list-group-item class="user-select-all">{{ $t("help.user_3") }}</b-list-group-item>
                        <b-list-group-item class="user-select-all">{{ $t("help.user_4") }}</b-list-group-item>                        
                    </b-list-group>
                </div>
            </b-sidebar>
            
            <br />
            <b-form-group horizontal :label="$t(`user.search`)" class="mb-0">
            <b-input-group>
                <b-form-input v-model="searchFilter" :placeholder="$t(`user.placeholder_search`)" />
                <b-input-group-append>
                <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t("user.btn_erase") }}</b-button>
                </b-input-group-append>
            </b-input-group>
            </b-form-group>
            <br />
            <div class="table-scrollable">
            <b-table striped hover :items="users" :fields="fields" :filter="searchFilter">
                <template #cell(review_status)="data">
                    <template v-if="data.item.review_status === 1">{{ $t("user.reviewstate_1") }}</template>
                    <template v-if="data.item.review_status === 2">{{ $t("user.reviewstate_2") }}</template>
                    <template v-if="data.item.review_status === 3">{{ $t("user.reviewstate_3") }}</template>
                    <template v-if="data.item.review_status === 4">{{ $t("user.reviewstate_4") }}</template>
                    <template v-if="data.item.review_status === 5">{{ $t("user.reviewstate_5") }}</template>
                    <template v-if="data.item.review_status === 6">{{ $t("user.reviewstate_6") }}</template>
                    <template v-if="data.item.review_status === 7">{{ $t("user.reviewstate_7") }}</template>
                </template>
                <template #cell(accepted_terms_of_service)="data">
                    <template v-if="data.item.accepted_terms_of_service === true">ok</template>
                    <template v-if="data.item.accepted_terms_of_service === false">ausstehend</template>
                </template>
                <template #cell(actions)="data">
                    <b-link class="m-1" href="#" :to="'/app/user/edit/' + data.item.id" ><v-icon name="edit"></v-icon></b-link>
                    <b-link class="m-1" href="#"  @click.prevent="requestConfirmation(data.item)"><v-icon name="trash"></v-icon></b-link>
                </template>
            </b-table>
            </div>
            <b-button variant="primary" class="button-margin" to="/app/user/new"><b-icon icon="person-plus" />{{ $t("user.btn_usernew") }}</b-button>
            <b-button variant="primary" class="button-margin" @click.prevent="showImport">
                <b-iconstack>
                    <b-icon stacked icon="person"></b-icon>
                    <b-icon stacked icon="arrow-down" shift-h="7" shift-v="3" scale="0.7"></b-icon>
                </b-iconstack>
                {{ $t("user.btn_userimport") }}
            </b-button>
        </b-card>
    </div>
    <b-modal ref="confirmationModal" hide-footer :title="$t(`user.modal_userdelete`)">
        <div class="d-block text-center">
            <p v-if="userContext">{{ $t("user.userdelete_text1") }}<strong>'{{userContext.name}}'</strong>{{ $t("user.userdelete_text2") }}</p>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="executeDelete">{{ $t("user.btn_delete") }}</b-button>
    </b-modal>
    <b-modal ref="importModal" hide-footer :title="$t(`user.modal_userimport`)">
        <div class="d-block text-center">
            {{ $t("user.modal_userimport_notes") }}
        </div>
        <b-form-file ref="excelFileInput" v-model="excelFile" :state="Boolean(excelFile)" :placeholder="$t(`user.placeholder_file`)"></b-form-file>
        <b-button class="mt-3" block @click="importExcelFile">{{ $t("user.btn_import") }}</b-button>
    </b-modal>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import importService from '../../../../services/ImportService';

export default {
    name: 'user-list',
    data() {
        return {
            searchFilter: '',
            userContext: null,
            excelFile: null,
        }
    },
    created() {
        this.listUsers();
    },
    watch: {
        '$route': 'listUsers'
    },
    methods: {
        ...mapActions({
            listUsers: 'users/fetchUsers',
            deleteUser: 'users/delete'
        }),
        requestConfirmation(user) {
            this.userContext = user;
            this.$refs.confirmationModal.show();
            this.$refs.excelFileInput.reset();
        },
        executeDelete() {
            this.$refs.confirmationModal.hide();
            this.deleteUser(this.userContext.id)
                .then(data => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("user.notifiy_deleteuser_title"),
                        type: 'success',
                        text: this.$t("user.notifiy_deleteuser_message"),
                    });

                    this.userContext = null;
                });
        },
        showImport() {
            this.excelFile = null;
            this.$refs.importModal.show();
        },
        importExcelFile() {
            importService.importUser(this.excelFile)
                .then(response => {
                    if(response.data.success) {
                        this.$refs.importModal.hide();
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notifiy_importuser_title"),
                            type: 'success',
                            text: this.$t("user.notifiy_importuser_message"),
                        });

                        this.listUsers();
                    }else {
                        this.$refs.importModal.hide();
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notifiy_importuser_error"),
                            type: 'error',
                            text: response.data.message
                        });
                        this.listUsers();
                    }
                })
                .catch(error => {

                })
        }
    },
    computed: {
        ...mapGetters({
            users: 'users/users'
        }),
        fields() {
            return [
                {
                    key: 'id',
                    label: 'Id'
                },
                {
                    key: 'name',
                    label: this.$t(`user.label_username`),
                    sortable: true
                },
                {
                    key: 'email',
                    label: this.$t(`user.label_email`),
                    sortable: true
                },
                {
                    key: 'company',
                    label: this.$t(`user.label_company`),
                    sortable: true
                },
                {
                    key: 'role.name',
                    label: this.$t(`user.label_role`)
                },
                {
                    key: 'product_group',
                    label: this.$t(`user.label_productgroup`),
                    sortable: true
                },
                {
                    key: 'review_status',
                    label: this.$t(`user.label_reviewstate`),
                    sortable: true
                },
                {
                    key: 'notes',
                    label: this.$t(`user.label_notes`),
                    sortable: true
                },
                {
                    key: 'accepted_terms_of_service',
                    label: this.$t(`user.label_accountactivation`),
                    sortable: true
                },
                {
                    key: 'country',
                    label: 'Land',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: this.$t(`user.label_actions`)
                }
            ];
        }

    }
};
</script>
