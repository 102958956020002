<template>
<b-modal ref="fileDialog" hide-footer :title="fileDialogTitle">
    <b-container>
        <b-form @submit.prevent="onFileUploadSubmit">
         
            <b-form-group :label="$t(`fileuploadmodal.file_name`)" label-for="file-name">
                <b-form-input id="file-name" v-model="currentFile.name" :state="!$v.currentFile.name.$error ? null : false" />
            </b-form-group>
           
            
            <b-form-group :label="$t(`fileuploadmodal.file_description`)" label-for="file-description">
                <b-form-textarea id="file-description" v-model="currentFile.description" :state="!$v.currentFile.description.$error ? null : false" rows="3" max-rows="3" no-resize />
            </b-form-group>
        
        
            <b-form-group :label="$t(`fileuploadmodal.file_filename`)" label-for="file-name" v-show="currentFile.id">
                <b-form-input id="file-filename" v-model="currentFile.filename" :state="editingFile && !$v.currentFile.filename.$error ? null : false" />
            </b-form-group>
        
        
            <b-form-group :label="$t(`fileuploadmodal.file_type`)" label-for="file-type" v-show="currentFile.id">
                <b-form-input id="file-type" v-model="currentFile.mimetype" readonly/>
            </b-form-group>
            
            <b-row v-if="!editingFile">
                <b-form-group :label="$t(`fileuploadmodal.file`)" label-for="file-file">
                    <b-form-file id="file-file" :placeholder="$t(`fileuploadmodal.placeholder`)" v-model="currentFile.file" :state="!editingFile && !$v.currentFile.file.$error ? null : false" accept/>
                </b-form-group>
            </b-row>
            <b-row>
                <b-button block type="submit"><b-icon icon="file-earmark-arrow-up" />{{fileDialogSaveButtonText}}</b-button>
            </b-row>
        </b-form>
    </b-container>
</b-modal>
</template>
<script>
import numberHelper from '../../../../../helpers/numberHelper';
import objectHelper from '../../../../../helpers/objectHelper';
import callForBidsService from '../../../../../services/CallForBidsService';
import { helpers, required } from 'vuelidate/lib/validators';
export default {
    name: 'auction-file-upload-module',
    props: ['savedCallback', 'auction'],
    data() {
        return {
            editingFile: false,
            currentFile: {}
        }
    },
    validations() {
        let validations = {
            currentFile: {
                name: {
                    required
                },
                description: {
                    required
                }
            }
        };

        if(!this.editingFile) {
            validations['currentFile']['file'] = { required };
        } else {
            validations['currentFile']['filename'] = { required };
        }

        return validations;
    },
    methods: {
        enterEditMode() {
            this.editingFile = true;
        },
        leaveEditMode() {
            this.editingFile = false;
        },
        showModal() {
            this.$v.$reset();
            this.editingFile = false;
            this.currentFile = {};
            this.$refs.fileDialog.show();
        },
        hideModal() {
            this.$refs.fileDialog.hide();
            this.currentFile = {};
            this.$v.$reset();
        },
        setFileItem(currentFile) {
            this.currentFile = currentFile;
        },
        onFileUploadSubmit() {
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }

            this.$store.commit('loading/setLoading', true);
            if(!this.editingFile) {
                callForBidsService.uploadFile(this.auction.id ,this.currentFile)
                    .then(response => {

                        this.$notify({
                                group: 'global',
                                title: this.$t("fileuploadmodal.notify_success_title"),
                                type: 'success',
                                text: this.$t("fileuploadmodal.notify_success_message")
                            });

                        this.$refs.fileDialog.hide();

                        this.currentFile = {
                            name: ''
                        };

                        if(this.savedCallback) {
                            this.savedCallback();
                        }
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);

                        this.$notify({
                            group: 'global',
                            title: this.$t("fileuploadmodal.notify_error_title"),
                            type: 'error',
                            text: error.message
                        });
                    })
            }else {
                callForBidsService.editFile({
                    id: this.currentFile.id,
                    name: this.currentFile.name,
                    filename: this.currentFile.filename,
                    description: this.currentFile.description
                }).then(response => {

                    this.$notify({
                        group: 'global',
                        title: this.$t("fileuploadmodal.notify_correction_title"),
                        type: 'success',
                        text: this.$t("fileuploadmodal.notify_correction_message")
                    });

                    if(this.savedCallback) {
                            this.savedCallback();
                        }

                    this.$refs.fileDialog.hide();

                    this.currentFile = {
                        name: ''
                    };
                })
                .catch(error => {
                    this.$notify({
                            group: 'global',
                            title: this.$t("fileuploadmodal.notify_correction_error"),
                            type: 'error',
                            text: error.message
                        });
                })
            }
        },
    },
    computed: {
        fileDialogTitle() {
            return !this.editingFile ? this.$t("fileuploadmodal.newfile") : this.$t("fileuploadmodal.editfile");
        },
        fileDialogSaveButtonText() {
            return !this.editingFile ? this.$t("fileuploadmodal.button_upload") : this.$t("fileuploadmodal.button_safe");
        }
    }
}
</script>
