<template>
<b-modal ref="bidUpload" hide-footer :title="$t(`auction_view.bid_upload_title`)">
    <b-container>
        <b-form @submit.prevent="onBidUploadSubmit">
            <b-row>
                <b-form-group :label="$t(`fileuploadmodal.file`)" label-for="file-file">
                    <b-form-file id="file-file" :placeholder="$t(`fileuploadmodal.placeholder`)" v-model="currentFile.file" :state="!editFile && !$v.currentFile.file.$error ? null : false" accept/>
                </b-form-group>
            </b-row>
            <b-row>
                <b-button type="submit"><b-icon icon="file-earmark-excel" />{{$t(`auction_view.bid_upload_button`)}}</b-button>
            </b-row>
        </b-form>
    </b-container>
</b-modal>
</template>
<script>
import objectHelper from '../../../../../helpers/objectHelper';
import callForBidsService from '../../../../../services/CallForBidsService';
import { helpers, required } from 'vuelidate/lib/validators';
export default {
    name: 'bid-upload-module',
    props: ['savedCallback', 'auction'],
    data() {
        return {
            currentFile: {}
        }
    },
    validations: {
        currentFile: {
            file: {
                required
            },
        }
    },
    methods: {
        showModal() {
            this.$v.$reset();
            this.currentFile = {};
            this.$refs.bidUpload.show();
        },
        hideModal() {
            this.$refs.bidUpload.hide();
            this.currentFile = {};
            this.$v.$reset();
        },
        onBidUploadSubmit() {
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }

            this.$store.commit('loading/setLoading', true);
            callForBidsService.newUploadBids(this.auction.id, this.currentFile)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if (response.data.success == true) {
                        this.$notify({
                                group: 'global',
                                title: this.$t("fileuploadmodal.notify_success_title"),
                                type: 'success',
                                text: this.$t("fileuploadmodal.notify_success_message")
                            });

                        this.$refs.bidUpload.hide();

                        this.currentFile = {
                            name: ''
                        };

                        if(this.savedCallback) {
                            this.savedCallback(response.data.data);
                        }
                    } else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("fileuploadmodal.notify_error_title"),
                            type: 'error',
                            text: response.data.message
                        });
                    }
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);

                    this.$notify({
                        group: 'global',
                        title: this.$t("fileuploadmodal.notify_error_title"),
                        type: 'error',
                        text: error.message
                    });
                });
        },
    },
}
</script>
