<template>
    <b-modal ref="auctionUpload" hide-footer :title="$t(`auction_list.${type}.import_title`)">
        <div class="d-block text-center">{{ $t(`auction_list.${type}.import_message`) }}</div>
        <b-form-file ref="excelFileInput" v-model="currentFile" :state="Boolean(currentFile)" :placeholder="$t(`auction_list.${type}.import_placeholder`)"></b-form-file>
        <b-button variant="primary" class="mt-3" block @click="importExcelFile">{{ $t(`auction_list.${type}.import_button`) }}</b-button>
        
        <hr class="mt-3">
        <div>
            <div class="d-block mt-4 h3">{{$t(`auction_list.${type}.import_help_title`)}}</div>
            <div class="d-block mt-2">{{$t(`auction_list.${type}.import_help_text`)}}</div>
            <b-button variant="secondary" class="mt-3" @click="downloadTemplate">{{$t(`auction_list.${type}.import_template`)}}</b-button>

        </div>

    </b-modal>
</template>
<script>
    import importService from '../../../../../services/ImportService';
    import DownloadService from '../../../../../services/DownloadService';
    import { required } from 'vuelidate/lib/validators';
    export default {
        name: 'auction-upload-module',
        props: ['savedCallback', 'isAuction'],
        data() {
            return {
                type: "auction",
                currentFile: {}
            }
        },
        validations: {
            currentFile: {
                file: {
                    required
                },
            }
        },
        methods: {
            showModal() {
                this.$v.$reset();
                this.currentFile = {};
                this.type = this.isAuction ? "auction" : "tender";
                this.$refs.auctionUpload.show();
            },
            hideModal() {
                this.$refs.auctionUpload.hide();
                this.currentFile = {};
                this.$v.$reset();
            },
            downloadTemplate() {
                this.$store.commit('loading/setLoading', true);
                importService.downloadTemplate('Importvorlage_Ausschreibung.xlsx')
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        DownloadService.processDownload(response.data, 'Importvorlage_Ausschreibung.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    });
            },
            importExcelFile() {
                if(this.isAuction) {
                    importService.importAuction(this.currentFile)
                        .then(response => {
                            if(response.data.success) {
                                this.$refs.excelFileInput.reset();
                                this.$refs.auctionUpload.hide();
                                if(this.savedCallback) {
                                    this.savedCallback(response.data.data);
                                }
                                this.$notify({
                                    group: 'global',
                                    title: this.$t(`auction_list.${this.type}.import_notify_title`),
                                    type: 'success',
                                    text: this.$t(`auction_list.${this.type}.import_notify_message`)
                                });
                            }
                        })
                        .catch(error => {
                        });
                }else {
                    importService.importCallForBids(this.currentFile)
                        .then(response => {
                            if(response.data.success) {
                                this.$refs.excelFileInput.reset();
                                this.$refs.auctionUpload.hide();
                                if(this.savedCallback) {
                                    this.savedCallback(response.data.data);
                                }
                                this.$notify({
                                    group: 'global',
                                    title: this.$t(`auction_list.${this.type}.import_notify_title`),
                                    type: 'success',
                                    text: this.$t(`auction_list.${this.type}.import_notify_message`)
                                });
                            }
                        })
                        .catch(error => {
                        });
                }
            },
        },
    }
    </script>
    