<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            Lieferkettensorgfaltspflichtengesetz (LkSG)
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-tabs>
                <b-tab title="Branchenrisiken" active>
                    <b-card title="Branchen" class="mb-3 shadow">
                        <div class="table-scrollable">
                            <template>
                                <div>
                                    <b-table striped hover
                                    :items="items_category"
                                    :fields="fields_category"
                                    responsive="sm"
                                    >
                                        <template #cell(Details)="row">
                                            <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails"></b-form-checkbox>
                                        </template>

                                        <template #row-details="row">
                                            <p>{{ row.item.Beschreibung }}</p>
                                        </template>
                                    </b-table>
                                </div>
                            </template>
                        </div>
                    </b-card>
                </b-tab>
                <b-tab title="Länderrisiken">
                    <b-card title="Länderliste" class="mb-3 shadow">
                        <div class="table-scrollable">
                            <template>
                                <div>
                                    <b-table striped hover
                                    :items="items_country"
                                    :fields="fields_country"
                                    responsive="sm"
                                    >
                                        <template #cell(Details)="row">
                                            <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails"></b-form-checkbox>
                                        </template>

                                        <template #row-details="row">
                                            <p>{{ row.item.Beschreibung }}</p>
                                        </template>
                                    </b-table>
                                </div>
                            </template>
                        </div>
                    </b-card>
            </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import numberHelper from '../../../../helpers/numberHelper';
import { dateHelper } from '../../../mixins/dateHelper';

export default {
    name: 'supplychainact',
    mixins: [dateHelper],
    data() {
        return {
            projectContext: null,
            stickyHeader: true,
            items_category: [
                { ID: 1, Jahr: '2022', NACE: 'C29', Branche: 'Herstellung von Kraftwagen und Kraftwagenteilen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 2, Jahr: '2022', NACE: 'G45', Branche: 'Handel mit Kraftfahrzeugen; Instandhaltung und Reparatur von Kraftfahrzeugen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 3, Jahr: '2022', NACE: 'N77.1', Branche: 'Vermietung von Kraftwagen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 4, Jahr: '2022', NACE: 'C20', Branche: 'Herstellung von chemischen Erzeugnissen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 5, Jahr: '2022', NACE: 'C26', Branche: 'Herstellung von Datenverarbeitungsgeräten, elektronischen und optischen Erzeugnissen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 6, Jahr: '2022', NACE: 'C27', Branche: 'Herstellung von elektrischen Ausrüstungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 7, Jahr: '2022', NACE: 'D35.1', Branche: 'Elektrizitätsversorgung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 8, Jahr: '2022', NACE: 'K64', Branche: 'Erbringung von Finanzdienstleistungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 9, Jahr: '2022', NACE: 'K65', Branche: 'Versicherungen, Rückversicherungen und Pensionskassen (ohne Sozialversicherung)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 10, Jahr: '2022', NACE: 'K66', Branche: 'Mit den Finanz- und Versicherungsdienstleistungen verbundene Tätigkeiten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 11, Jahr: '2022', NACE: 'G46.3', Branche: 'Großhandel mit Nahrungs- und Genussmitteln, Getränken und Tabakwaren', Forschungsbericht: '-', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 12, Jahr: '2022', NACE: 'G46.2', Branche: 'Großhandel mit landwirtschaftlichen Grundstoffen und lebenden Tieren', Forschungsbericht: '-', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 13, Jahr: '2022', NACE: 'G46.4', Branche: 'Großhandel mit Gebrauchs- und Verbrauchsgütern', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 14, Jahr: '2022', NACE: 'G46.1', Branche: 'Handelsvermittlung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 15, Jahr: '2022', NACE: 'G46.5', Branche: 'Großhandel mit Geräten der Informations- und Kommunikationstechnik', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 16, Jahr: '2022', NACE: 'G46.6', Branche: 'Großhandel mit sonstigen Maschinen, Ausrüstungen und Zubehör', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 17, Jahr: '2022', NACE: 'G46.7', Branche: 'Sonstiger Großhandel', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 18, Jahr: '2022', NACE: 'G46.9', Branche: 'Großhandel ohne ausgeprägten Schwerpunkt', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 19, Jahr: '2022', NACE: 'G47', Branche: 'Einzelhandel (ohne Handel mit Kraftfahrzeugen)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 20, Jahr: '2022', NACE: 'C28', Branche: 'Maschinenbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 21, Jahr: '2022', NACE: 'C25', Branche: 'Herstellung von Metallerzeugnissen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 22, Jahr: '2022', NACE: 'C25.1', Branche: 'Stahl- und Leichtmetallbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 23, Jahr: '2022', NACE: 'C25.11', Branche: 'Herstellung von Metallkonstruktionen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 24, Jahr: '2022', NACE: 'C25.12', Branche: 'Herstellung von Ausbauelementen aus Metall', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 25, Jahr: '2022', NACE: 'C25.2', Branche: 'Herstellung von Metalltanks und -behältern; Herstellung von Heizkörpern und -kesseln für Zentralheizungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 26, Jahr: '2022', NACE: 'C25.21', Branche: 'Herstellung von Heizkörpern und –kesseln für Zentralheizungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 27, Jahr: '2022', NACE: 'C25.29', Branche: 'Herstellung von Sammelbehältern, Tanks u.ä. Behältern aus Metall', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 28, Jahr: '2022', NACE: 'C25.3', Branche: 'Herstellung von Dampfkesseln (ohne Zentralheizungskessel)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 29, Jahr: '2022', NACE: 'C25.4', Branche: 'Herstellung von Waffen und Munition', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 30, Jahr: '2022', NACE: 'C25.5', Branche: 'Herstellung von Schmiede-, Press-, Zieh- und Stanzteilen, gewalzten Ringen und pulvermetallurgischen Erzeugnissen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 31, Jahr: '2022', NACE: 'C25.6', Branche: 'Oberflächenveredlung und Wärmebehandlung; Mechanik a.n.g.', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 32, Jahr: '2022', NACE: 'C25.61', Branche: 'Oberflächenveredlung und Wärmebehandlung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 33, Jahr: '2022', NACE: 'C25.62', Branche: 'Mechanik a.n.g.', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 34, Jahr: '2022', NACE: 'C25.7', Branche: 'Herstellung von Schneidwaren, Werkzeugen, Schlössern und Beschlägen aus unedlen Metallen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 35, Jahr: '2022', NACE: 'C25.71', Branche: 'Herstellung von Schneidwaren und Bestecken aus unedlen Metallen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 36, Jahr: '2022', NACE: 'C25.72', Branche: 'Herstellung von Schlössern und Beschlägen aus unedlen Metallen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 37, Jahr: '2022', NACE: 'C25.73', Branche: 'Herstellung von Werkzeugen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 38, Jahr: '2022', NACE: 'C25.9', Branche: 'Herstellung von sonstigen Metallwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 39, Jahr: '2022', NACE: 'C25.91', Branche: 'Herstellung von Fässern, Trommeln, Dosen, Eimern u.ä. Behältern aus Metall', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 40, Jahr: '2022', NACE: 'C25.92', Branche: 'Herstellung von Verpackungen und Verschlüssen aus Eisen, Stahl und NE-Metall', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 41, Jahr: '2022', NACE: 'C25.93', Branche: 'Herstellung von Drahtwaren, Ketten und Federn', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 42, Jahr: '2022', NACE: 'C25.94', Branche: 'Herstellung von Schrauben und Nieten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 43, Jahr: '2022', NACE: 'C25.99', Branche: 'Herstellung von sonstigen Metallwaren a.n.g.', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 44, Jahr: '2022', NACE: 'C24', Branche: 'Metallerzeugung und -bearbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 45, Jahr: '2022', NACE: 'C24.1', Branche: 'Erzeugung von Roheisen, Stahl und Ferrolegierungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 46, Jahr: '2022', NACE: 'C24.2', Branche: 'Herstellung von Stahlrohren, Rohrform-, Rohrverschluss- und Rohrverbindungsstücken aus Stahl', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 47, Jahr: '2022', NACE: 'C24.3', Branche: 'Sonstige erste Bearbeitung von Eisen und Stahl', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 48, Jahr: '2022', NACE: 'C24.31', Branche: 'Herstellung von Blankstahl', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 49, Jahr: '2022', NACE: 'C24.32', Branche: 'Herstellung von Kaltband mit einer Breite von weniger als 600 mm', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 50, Jahr: '2022', NACE: 'C24.33', Branche: 'Herstellung von Kaltprofilen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 51, Jahr: '2022', NACE: 'C24.34', Branche: 'Herstellung von kaltgezogenem Draht', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 52, Jahr: '2022', NACE: 'C24.4', Branche: 'Erzeugung und erste Bearbeitung von NE-Metallen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 53, Jahr: '2022', NACE: 'C24.41', Branche: 'Erzeugung und erste Bearbeitung von Edelmetallen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 54, Jahr: '2022', NACE: 'C24.42', Branche: 'Erzeugung und erste Bearbeitung von Aluminium', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 55, Jahr: '2022', NACE: 'C24.43', Branche: 'Erzeugung und erste Bearbeitung von Blei, Zink und Zinn', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 56, Jahr: '2022', NACE: 'C24.44', Branche: 'Erzeugung und erste Bearbeitung von Kupfer', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 57, Jahr: '2022', NACE: 'C24.45', Branche: 'Erzeugung und erste Bearbeitung von sonstigen NE-Metallen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 58, Jahr: '2022', NACE: 'C24.46', Branche: 'Aufbereitung von Kernbrennstoffen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 59, Jahr: '2022', NACE: 'C24.5', Branche: 'Gießereien', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 60, Jahr: '2022', NACE: 'C24.51', Branche: 'Eisengießereien', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 61, Jahr: '2022', NACE: 'C24.52', Branche: 'Stahlgießereien', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 62, Jahr: '2022', NACE: 'C24.53', Branche: 'Leichtmetallgießereien', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 63, Jahr: '2022', NACE: 'C24.54', Branche: 'Buntmetallgießereien', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 64, Jahr: '2022', NACE: 'C10', Branche: 'Herstellung von Nahrungs- und Futtermitteln', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 65, Jahr: '2022', NACE: 'C10.1', Branche: 'Schlachten und Fleischverarbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 66, Jahr: '2022', NACE: 'C10.11', Branche: 'Schlachten (ohne Schlachten von Geflügel)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 67, Jahr: '2022', NACE: 'C10.12', Branche: 'Schlachten von Geflügel', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 68, Jahr: '2022', NACE: 'C10.13', Branche: 'Fleischverarbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 69, Jahr: '2022', NACE: 'C10.2', Branche: 'Fischverarbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 70, Jahr: '2022', NACE: 'C10.3', Branche: 'Obst- und Gemüseverarbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 71, Jahr: '2022', NACE: 'C10.31', Branche: 'Kartoffelverarbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 72, Jahr: '2022', NACE: 'C10.32', Branche: 'Herstellung von Frucht- und Gemüsesäften', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 73, Jahr: '2022', NACE: 'C10.39', Branche: 'Sonstige Verarbeitung von Obst und Gemüse', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 74, Jahr: '2022', NACE: 'C10.4', Branche: 'Herstellung von pflanzlichen und tierischen Ölen und Fetten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 75, Jahr: '2022', NACE: 'C10.41', Branche: 'Herstellung von Ölen und Fetten (ohne Margarine u.ä. Nahrungsfette)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 76, Jahr: '2022', NACE: 'C10.42', Branche: 'Herstellung von Margarine u.ä. Nahrungsfetten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 77, Jahr: '2022', NACE: 'C10.5', Branche: 'Milchverarbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 78, Jahr: '2022', NACE: 'C10.51', Branche: 'Milchverarbeitung (ohne Herstellung von Speiseeis)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 79, Jahr: '2022', NACE: 'C10.52', Branche: 'Herstellung von Speiseeis', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 80, Jahr: '2022', NACE: 'C10.6', Branche: 'Mahl- und Schälmühlen, Herstellung von Stärke und Stärkeerzeugnissen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 81, Jahr: '2022', NACE: 'C10.61', Branche: 'Mahl- und Schälmühlen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 82, Jahr: '2022', NACE: 'C10.62', Branche: 'Herstellung von Stärke und Stärkeerzeugnissen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 83, Jahr: '2022', NACE: 'C10.7', Branche: 'Herstellung von Back- und Teigwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 84, Jahr: '2022', NACE: 'C10.71', Branche: 'Herstellung von Backwaren (ohne Dauerbackwaren)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 85, Jahr: '2022', NACE: 'C10.72', Branche: 'Herstellung von Dauerbackwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 86, Jahr: '2022', NACE: 'C10.73', Branche: 'Herstellung von Teigwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 87, Jahr: '2022', NACE: 'C10.8', Branche: 'Herstellung von sonstigen Nahrungsmitteln', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 88, Jahr: '2022', NACE: 'C10.81', Branche: 'Herstellung von Zucker', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 89, Jahr: '2022', NACE: 'C10.82', Branche: 'Herstellung von Süßwaren (ohne Dauerbackwaren)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 90, Jahr: '2022', NACE: 'C10.83', Branche: 'Verarbeitung von Kaffee und Tee, Herstellung von Kaffee-Ersatz', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 91, Jahr: '2022', NACE: 'C10.84', Branche: 'Herstellung von Würzmitteln und Soßen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 92, Jahr: '2022', NACE: 'C10.85', Branche: 'Herstellung von Fertiggerichten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 93, Jahr: '2022', NACE: 'C10.86', Branche: 'Herstellung von homogenisierten und diätetischen Nahrungsmitteln', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 94, Jahr: '2022', NACE: 'C10.89', Branche: 'Herstellung von sonstigen Nahrungsmitteln a.n.g.', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 95, Jahr: '2022', NACE: 'C10.9', Branche: 'Herstellung von Futtermitteln', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 96, Jahr: '2022', NACE: 'C10.91', Branche: 'Herstellung von Futtermitteln für Nutztiere', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 97, Jahr: '2022', NACE: 'C10.92', Branche: 'Herstellung von Futtermitteln für sonstige Tiere', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 98, Jahr: '2022', NACE: 'C11', Branche: 'Getränkeherstellung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 99, Jahr: '2022', NACE: 'C11.01', Branche: 'Herstellung von Spirituosen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 100, Jahr: '2022', NACE: 'C11.02', Branche: 'Herstellung von Traubenwein', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 101, Jahr: '2022', NACE: 'C11.03', Branche: 'Herstellung von Apfelwein und anderen Fruchtweinen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 102, Jahr: '2022', NACE: 'C11.04', Branche: 'Herstellung von Wermutwein und sonstigen aromatisierten Weinen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 103, Jahr: '2022', NACE: 'C11.05', Branche: 'Herstellung von Bier', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 104, Jahr: '2022', NACE: 'C11.06', Branche: 'Herstellung von Malz', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 105, Jahr: '2022', NACE: 'C11.07', Branche: 'Herstellung von Erfrischungsgetränken; Gewinnung natürlicher Mineralwässer', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 106, Jahr: '2022', NACE: 'C12', Branche: 'Tabakverarbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 107, Jahr: '2022', NACE: 'N79', Branche: 'Reisebüros, Reiseveranstalter und Erbringung sonstiger Reservierungsdienstleistungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 108, Jahr: '2022', NACE: 'R93', Branche: 'Erbringung von Dienstleistungen des Sports, der Unterhaltung und der Erholung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 109, Jahr: '2022', NACE: 'F41', Branche: 'Hochbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 110, Jahr: '2022', NACE: 'F42', Branche: 'Tiefbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 111, Jahr: '2022', NACE: 'F43', Branche: 'Vorbereitende Baustellenarbeiten, Bauinstallation und sonstiges Ausbaugewerbe', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 112, Jahr: '2022', NACE: 'B05', Branche: 'Kohlenbergbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 113, Jahr: '2022', NACE: 'B05.1', Branche: 'Steinkohlenbergbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 114, Jahr: '2022', NACE: 'B05.2', Branche: 'Braunkohlenbergbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 115, Jahr: '2022', NACE: 'B06', Branche: 'Gewinnung von Erdöl und Erdgas', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 116, Jahr: '2022', NACE: 'B06.1', Branche: 'Gewinnung von Erdöl', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 117, Jahr: '2022', NACE: 'B06.2', Branche: 'Gewinnung von Erdgas', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 118, Jahr: '2022', NACE: 'B07', Branche: 'Erzbergbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 119, Jahr: '2022', NACE: 'B07.1', Branche: 'Eisenerzbergbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 120, Jahr: '2022', NACE: 'B07.2', Branche: 'NE-Metallerzbergbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 121, Jahr: '2022', NACE: 'B07.21', Branche: 'Bergbau auf Uran- und Thoriumerze', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 122, Jahr: '2022', NACE: 'B07.29', Branche: 'Sonstiger NE-Metallerzbergbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 123, Jahr: '2022', NACE: 'B08', Branche: 'Gewinnung von Steinen und Erden, sonstiger Bergbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 124, Jahr: '2022', NACE: 'B08.1', Branche: 'Gewinnung von Natursteinen, Kies, Sand, Ton und Kaolin', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 125, Jahr: '2022', NACE: 'B08.11', Branche: 'Gewinnung von Naturwerksteinen und Natursteinen, Kalk- und Gipsstein, Kreide und Schiefer', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 126, Jahr: '2022', NACE: 'B08.12', Branche: 'Gewinnung von Kies, Sand, Ton und Kaolin', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 127, Jahr: '2022', NACE: 'B08.9', Branche: 'Sonstiger Bergbau; Gewinnung von Steinen und Erden a.n.g.', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 128, Jahr: '2022', NACE: 'B08.91', Branche: 'Bergbau auf chemische und Düngemittelminerale', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 129, Jahr: '2022', NACE: 'B08.92', Branche: 'Torfgewinnung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 130, Jahr: '2022', NACE: 'B08.93', Branche: 'Gewinnung von Salz', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 131, Jahr: '2022', NACE: 'B08.99', Branche: 'Gewinnung von Steinen und Erden a.n.g.', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 132, Jahr: '2022', NACE: 'B09', Branche: 'Erbringung von Dienstleistungen für den Bergbau und für die Gewinnung von Steinen und Erden', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 133, Jahr: '2022', NACE: 'B09.1', Branche: 'Erbringung von Dienstleistungen für die Gewinnung von Erdöl und Erdgas', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 134, Jahr: '2022', NACE: 'B09.9', Branche: 'Erbringung von Dienstleistungen für den sonstigen Bergbau und die Gewinnung von Steinen und Erden', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 135, Jahr: '2022', NACE: 'E37', Branche: 'Abwasserentsorgung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 136, Jahr: '2022', NACE: 'E38', Branche: 'Sammlung, Behandlung und Beseitigung von Abfällen; Rückgewinnung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 137, Jahr: '2022', NACE: 'E39', Branche: 'Beseitigung von Umweltverschmutzungen und sonstige Entsorgung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 138, Jahr: '2022', NACE: 'A02', Branche: 'Forstwirtschaft und Holzeinschlag', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 139, Jahr: '2022', NACE: 'I55', Branche: 'Beherbergung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 140, Jahr: '2022', NACE: 'I56', Branche: 'Gastronomie', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 141, Jahr: '2022', NACE: 'C23', Branche: 'Herstellung von Glas und Glaswaren, Keramik, Verarbeitung von Steinen und Erden', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 142, Jahr: '2022', NACE: 'C22', Branche: 'Herstellung von Gummi- und Kunststoffwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 143, Jahr: '2022', NACE: 'C16', Branche: 'Herstellung von Holz-, Flecht-, Korb- und Korkwaren (ohne Möbel)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 144, Jahr: '2022', NACE: 'C17', Branche: 'Herstellung von Papier, Pappe und Waren daraus', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 145, Jahr: '2022', NACE: 'C18', Branche: 'Herstellung von Druckerzeugnissen; Vervielfältigung von bespielten Ton-, Bild- und Datenträgern', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 146, Jahr: '2022', NACE: 'L68.1', Branche: 'Kauf und Verkauf von eigenen Grundstücken, Gebäuden und Wohnungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 147, Jahr: '2022', NACE: 'C19', Branche: 'Kokerei und Mineralölverarbeitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 148, Jahr: '2022', NACE: 'A01', Branche: 'Landwirtschaft, Jagd und damit verbundene Tätigkeiten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 149, Jahr: '2022', NACE: 'A01.1', Branche: 'Anbau einjähriger Pflanzen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 150, Jahr: '2022', NACE: 'A01.11', Branche: 'Anbau von Getreide (ohne Reis), Hülsenfrüchten und Ölsaaten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 151, Jahr: '2022', NACE: 'A01.12', Branche: 'Anbau von Reis', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 152, Jahr: '2022', NACE: 'A01.13', Branche: 'Anbau von Gemüse und Melonen sowie Wurzeln und Knollen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 153, Jahr: '2022', NACE: 'A01.14', Branche: 'Anbau von Zuckerrohr', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 154, Jahr: '2022', NACE: 'A01.15', Branche: 'Anbau von Tabak', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 155, Jahr: '2022', NACE: 'A01.16', Branche: 'Anbau von Faserpflanzen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 156, Jahr: '2022', NACE: 'A01.19', Branche: 'Anbau von sonstigen einjährigen Pflanzen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 157, Jahr: '2022', NACE: 'A01.2', Branche: 'Anbau mehrjähriger Pflanzen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 158, Jahr: '2022', NACE: 'A01.21', Branche: 'Anbau von Wein- und Tafeltrauben', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 159, Jahr: '2022', NACE: 'A01.22', Branche: 'Anbau von tropischen und subtropischen Früchten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 160, Jahr: '2022', NACE: 'A01.23', Branche: 'Anbau von Zitrusfrüchten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 161, Jahr: '2022', NACE: 'A01.24', Branche: 'Anbau von Kern- und Steinobst', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 162, Jahr: '2022', NACE: 'A01.25', Branche: 'Anbau von sonstigem Obst und Nüssen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 163, Jahr: '2022', NACE: 'A01.26', Branche: 'Anbau von ölhaltigen Früchten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 164, Jahr: '2022', NACE: 'A01.27', Branche: 'Anbau von Pflanzen zur Herstellung von Getränken', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 165, Jahr: '2022', NACE: 'A01.28', Branche: 'Anbau von Gewürzpflanzen, Pflanzen für aromatische, narkotische und pharmazeutische Zwecke', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 166, Jahr: '2022', NACE: 'A01.29', Branche: 'Anbau sonstiger mehrjähriger Pflanzen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 167, Jahr: '2022', NACE: 'A01.3', Branche: 'Betrieb von Baumschulen sowie Anbau von Pflanzen zu Vermehrungszwecken', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 168, Jahr: '2022', NACE: 'A01.4', Branche: 'Tierhaltung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 169, Jahr: '2022', NACE: 'A01.41', Branche: 'Haltung von Milchkühen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 170, Jahr: '2022', NACE: 'A01.42', Branche: 'Haltung von anderen Rindern', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 171, Jahr: '2022', NACE: 'A01.43', Branche: 'Haltung von Pferden und Eseln', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 172, Jahr: '2022', NACE: 'A01.44', Branche: 'Haltung von Kamelen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 173, Jahr: '2022', NACE: 'A01.45', Branche: 'Haltung von Schafen und Ziegen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 174, Jahr: '2022', NACE: 'A01.46', Branche: 'Haltung von Schweinen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 175, Jahr: '2022', NACE: 'A01.47', Branche: 'Haltung von Geflügel', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 176, Jahr: '2022', NACE: 'A01.49', Branche: 'Sonstige Tierhaltung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 177, Jahr: '2022', NACE: 'A01.5', Branche: 'Gemischte Landwirtschaft', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 178, Jahr: '2022', NACE: 'A01.6', Branche: 'Erbringung von landwirtschaftlichen Dienstleistungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 179, Jahr: '2022', NACE: 'A01.61', Branche: 'Erbringung von landwirtschaftlichen Dienstleistungen für den Pflanzenbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 180, Jahr: '2022', NACE: 'A01.62', Branche: 'Erbringung von landwirtschaftlichen Dienstleistungen für die Tierhaltung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 181, Jahr: '2022', NACE: 'A01.63', Branche: 'Nach der Ernte anfallende Tätigkeiten in der pflanzlichen Erzeugung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 182, Jahr: '2022', NACE: 'A01.64', Branche: 'Saatgutaufbereitung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 183, Jahr: '2022', NACE: 'A01.7', Branche: 'Jagd, Fallenstellerei und damit verbundene Tätigkeiten', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 184, Jahr: '2022', NACE: 'A03', Branche: 'Fischerei und Aquakultur', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 185, Jahr: '2022', NACE: 'A03.1', Branche: 'Fischerei', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 186, Jahr: '2022', NACE: 'A03.11', Branche: 'Meeresfischerei', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 187, Jahr: '2022', NACE: 'A03.12', Branche: 'Süßwasserfischerei', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 188, Jahr: '2022', NACE: 'A03.2', Branche: 'Aquakultur', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 189, Jahr: '2022', NACE: 'A03.21', Branche: 'Meeresaquakultur', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 190, Jahr: '2022', NACE: 'A03.22', Branche: 'Süßwasseraquakultur', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 191, Jahr: '2022', NACE: 'C31', Branche: 'Herstellung von Möbeln', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 192, Jahr: '2022', NACE: 'C32', Branche: 'Herstellung von sonstigen Waren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 193, Jahr: '2022', NACE: 'N78', Branche: 'Vermittlung und Überlassung von Arbeitskräften', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 194, Jahr: '2022', NACE: 'N80', Branche: 'Wach- und Sicherheitsdienste sowie Detekteien', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 195, Jahr: '2022', NACE: 'N81', Branche: 'Gebäudebetreuung; Garten- und Landschaftsbau', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 196, Jahr: '2022', NACE: 'C21', Branche: 'Herstellung von pharmazeutischen Erzeugnissen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 197, Jahr: '2022', NACE: 'J61', Branche: 'Telekommunikation', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 198, Jahr: '2022', NACE: 'J62', Branche: 'Erbringung von Dienstleistungen der Informationstechnologie', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 199, Jahr: '2022', NACE: 'J63', Branche: 'Informationsdienstleistungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 200, Jahr: '2022', NACE: 'C13', Branche: 'Herstellung von Textilien', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 201, Jahr: '2022', NACE: 'C13.1', Branche: 'Spinnstoffaufbereitung und Spinnerei', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 202, Jahr: '2022', NACE: 'C13.2', Branche: 'Weberei', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 203, Jahr: '2022', NACE: 'C13.3', Branche: 'Veredlung von Textilien und Bekleidung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 204, Jahr: '2022', NACE: 'C13.9', Branche: 'Herstellung von sonstigen Textilwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 205, Jahr: '2022', NACE: 'C13.91', Branche: 'Herstellung von gewirktem und gestricktem Stoff', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 206, Jahr: '2022', NACE: 'C13.92', Branche: 'Herstellung von konfektionierten Textilwaren (ohne Bekleidung)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 207, Jahr: '2022', NACE: 'C13.93', Branche: 'Herstellung von Teppichen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 208, Jahr: '2022', NACE: 'C13.94', Branche: 'Herstellung von Seilerwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 209, Jahr: '2022', NACE: 'C13.95', Branche: 'Herstellung von Vliesstoff und Erzeugnissen daraus (ohne Bekleidung)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 210, Jahr: '2022', NACE: 'C13.96', Branche: 'Herstellung von technischen Textilien', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 211, Jahr: '2022', NACE: 'C13.99', Branche: 'Herstellung von sonstigen Textilwaren a.n.g.', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 212, Jahr: '2022', NACE: 'C14', Branche: 'Herstellung von Bekleidung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 213, Jahr: '2022', NACE: 'C14.1', Branche: 'Herstellung von Bekleidung (ohne Pelzbekleidung)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 214, Jahr: '2022', NACE: 'C14.11', Branche: 'Herstellung von Lederbekleidung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 215, Jahr: '2022', NACE: 'C14.12', Branche: 'Herstellung von Arbeits- und Berufsbekleidung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 216, Jahr: '2022', NACE: 'C14.13', Branche: 'Herstellung von sonstiger Oberbekleidung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 217, Jahr: '2022', NACE: 'C14.14', Branche: 'Herstellung von Wäsche', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 218, Jahr: '2022', NACE: 'C14.19', Branche: 'Herstellung von sonstiger Bekleidung und Bekleidungszubehör a.n.g.', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 219, Jahr: '2022', NACE: 'C14.2', Branche: 'Herstellung von Pelzwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 220, Jahr: '2022', NACE: 'C14.3', Branche: 'Herstellung von Bekleidung aus gewirktem und gestricktem Stoff', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 221, Jahr: '2022', NACE: 'C14.31', Branche: 'Herstellung von Strumpfwaren', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 222, Jahr: '2022', NACE: 'C14.39', Branche: 'Herstellung von sonstiger Bekleidung aus gewirktem und gestricktem Stoff', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 223, Jahr: '2022', NACE: 'C15', Branche: 'Herstellung von Leder, Lederwaren und Schuhen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 224, Jahr: '2022', NACE: 'C15.1', Branche: 'Herstellung von Leder und Lederwaren (ohne Herstellung von Lederbekleidung)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 225, Jahr: '2022', NACE: 'C15.11', Branche: 'Herstellung von Leder und Lederfaserstoff; Zurichtung und Färben von Fellen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 226, Jahr: '2022', NACE: 'C15.12', Branche: 'Lederverarbeitung (ohne Herstellung von Lederbekleidung)', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 227, Jahr: '2022', NACE: 'C15.2', Branche: 'Herstellung von Schuhen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: 'Hoch', Risiko_Branche: '' },
                { ID: 228, Jahr: '2022', NACE: 'H49', Branche: 'Landverkehr und Transport in Rohrfernleitungen', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 229, Jahr: '2022', NACE: 'H50', Branche: 'Schifffahrt', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 230, Jahr: '2022', NACE: 'H51', Branche: 'Luftfahrt', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 231, Jahr: '2022', NACE: 'H52', Branche: 'Lagerei sowie Erbringung von sonstigen Dienstleistungen für den Verkehr', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 232, Jahr: '2022', NACE: 'C25.4', Branche: 'Herstellung von Waffen und Munition', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 233, Jahr: '2022', NACE: 'O84.22', Branche: 'Verteidigung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 234, Jahr: '2022', NACE: 'E36', Branche: 'Wasserversorgung', Forschungsbericht: 'Hoch', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 235, Jahr: '2022', NACE: 'M71', Branche: 'Architektur- und Ingenieurbüros; technische, physikalische und chemische Untersuchung', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 236, Jahr: '2022', NACE: 'M69', Branche: 'Rechts- und Steuerberatung, Wirtschaftsprüfung', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 237, Jahr: '2022', NACE: 'R91', Branche: 'Bibliotheken, Archive, Museen, botanische und zoologische Gärten', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 238, Jahr: '2022', NACE: 'P85.1', Branche: 'Kindergärten und Vorschulen', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 239, Jahr: '2022', NACE: 'N82', Branche: 'Erbringung von wirtschaftlichen Dienstleistungen für Unternehmen und Privatpersonen a.n.g.', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 240, Jahr: '2022', NACE: 'U', Branche: 'Exterritoriale Organisationen und Körperschaften', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 241, Jahr: '2022', NACE: 'M72', Branche: 'Forschung und Entwicklung', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 242, Jahr: '2022', NACE: 'Q86', Branche: 'Gesundheitswesen', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 243, Jahr: '2022', NACE: 'R92', Branche: 'Spiel-, Wett- und Lotteriewesen', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 244, Jahr: '2022', NACE: 'Q87', Branche: 'Heime (ohne Erholungs- und Ferienheime)', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 245, Jahr: '2022', NACE: 'T98', Branche: 'Herstellung von Waren und Erbringung von Dienstleistungen durch private Haushalte für den Eigenbedarf ohne ausgeprägten Schwerpunkt', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 246, Jahr: '2022', NACE: 'J59', Branche: 'Herstellung, Verleih und Vertrieb von Filmen und Fernsehprogrammen; Kinos; Tonstudios und Verlegen von Musik', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 247, Jahr: '2022', NACE: 'S94', Branche: 'Interessenvertretungen sowie kirchliche und sonstige religiöse Vereinigungen (ohne Sozialwesen und Sport)', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 248, Jahr: '2022', NACE: 'R90', Branche: 'Kreative, künstlerische und unterhaltende Tätigkeiten', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 249, Jahr: '2022', NACE: 'O84.1', Branche: 'Öffentliche Verwaltung', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 250, Jahr: '2022', NACE: 'T97', Branche: 'Private Haushalte mit Hauspersonal', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 251, Jahr: '2022', NACE: 'C33', Branche: 'Reparatur und Installation von Maschinen und Ausrüstungen', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 252, Jahr: '2022', NACE: 'S95', Branche: 'Reparatur von Datenverarbeitungsgeräten und Gebrauchsgütern', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 253, Jahr: '2022', NACE: 'J60', Branche: 'Rundfunkveranstalter', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 254, Jahr: '2022', NACE: 'C30', Branche: 'Sonstiger Fahrzeugbau', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 255, Jahr: '2022', NACE: 'M74', Branche: 'Sonstige freiberufliche, wissenschaftliche und technische Tätigkeiten', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 256, Jahr: '2022', NACE: 'Q88', Branche: 'Sozialwesen (ohne Heime)', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 257, Jahr: '2022', NACE: 'J58', Branche: 'Verlagswesen', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 258, Jahr: '2022', NACE: 'M70', Branche: 'Verwaltung und Führung von Unternehmen und Betrieben; Unternehmensberatung', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 259, Jahr: '2022', NACE: 'M75', Branche: 'Veterinärwesen', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 260, Jahr: '2022', NACE: 'M73', Branche: 'Werbung und Marktforschung', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 261, Jahr: '2022', NACE: 'N77', Branche: 'Vermietung von beweglichen Sachen', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },
                { ID: 262, Jahr: '2022', NACE: 'H53', Branche: 'Post-, Kurier- und Expressdienste', Forschungsbericht: 'Niedrig', EU_Supply_Chain_Act: '-', Risiko_Branche: '' },

            ],
            
            items_country: [
                { ID: 1, Jahr: '2022', Länderkürzel: 'EG', Land: 'Ägypten', CPI: '33', CPI_risk: 'Hoch', Childrens_Rights: '4,5', Childrens_Rights_risk: 'Mittel', GSI: '0,605', GSI_risk: 'Mittel', NSC: '10,7', NSC_risk: 'Hoch', WJP: '0,35', WJP_risk: 'Hoch', ITUC: '5', ITUC_risk: 'Hoch', HFI: '4,49', HFI_risk: 'Hoch', PFI: '18', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '35,5', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 2, Jahr: '2022', Länderkürzel: 'AL', Land: 'Albanien', CPI: '35', CPI_risk: 'Hoch', Childrens_Rights: '3,5', Childrens_Rights_risk: 'Mittel', GSI: '0,452', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,49', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '8,14', HFI_risk: 'Mittel', PFI: '67', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '47,1', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 3, Jahr: '2022', Länderkürzel: 'AD', Land: 'Andorra', CPI: 'Keine Daten', CPI_risk: 'Keine Daten', Childrens_Rights: '1,9', Childrens_Rights_risk: 'Niedrig', GSI: 'Keine Daten', GSI_risk: 'Keine Daten', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: 'Keine Daten', HFI_risk: 'Keine Daten', PFI: '93', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: 'Keine Daten', EPI_risk: 'Keine Daten', Umweltrisiko_sum_risk: 'Keine Daten', country_sum_risk: 'Hoch' },
                { ID: 4, Jahr: '2022', Länderkürzel: 'AU', Land: 'Australien', CPI: '73', CPI_risk: 'Niedrig', Childrens_Rights: '2,3', Childrens_Rights_risk: 'Niedrig', GSI: '0,043', GSI_risk: 'Niedrig', NSC: '1,62', NSC_risk: 'Niedrig', WJP: '0,79', WJP_risk: 'Niedrig', ITUC: '3', ITUC_risk: 'Mittel', HFI: '8,84', HFI_risk: 'Niedrig', PFI: '97', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '60,1', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 5, Jahr: '2022', Länderkürzel: 'BE', Land: 'Belgien', CPI: '73', CPI_risk: 'Niedrig', Childrens_Rights: '2,3', Childrens_Rights_risk: 'Niedrig', GSI: '0,02', GSI_risk: 'Niedrig', NSC: '1,41', NSC_risk: 'Niedrig', WJP: '0,97', WJP_risk: 'Niedrig', ITUC: '3', ITUC_risk: 'Mittel', HFI: '8,61', HFI_risk: 'Niedrig', PFI: '96', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '58,2', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 6, Jahr: '2022', Länderkürzel: 'BA', Land: 'Bosnien und Herzegowina', CPI: '35', CPI_risk: 'Hoch', Childrens_Rights: '3,5', Childrens_Rights_risk: 'Mittel', GSI: '0,464', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,52', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '7,54', HFI_risk: 'Mittel', PFI: '53', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '39,4', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 7, Jahr: '2022', Länderkürzel: 'BG', Land: 'Bulgarien', CPI: '42', CPI_risk: 'Mittel', Childrens_Rights: '3,4', Childrens_Rights_risk: 'Mittel', GSI: '0,313', GSI_risk: 'Mittel', NSC: '3,4', NSC_risk: 'Niedrig', WJP: '0,54', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '8,08', HFI_risk: 'Mittel', PFI: '78', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '51,9', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 8, Jahr: '2022', Länderkürzel: 'CN', Land: 'China', CPI: '45', CPI_risk: 'Mittel', Childrens_Rights: '5,6', Childrens_Rights_risk: 'Mittel', GSI: '0,507', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,47', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '5,57', HFI_risk: 'Hoch', PFI: '9', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '28,4', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 9, Jahr: '2022', Länderkürzel: 'DK', Land: 'Dänemark', CPI: '88', CPI_risk: 'Niedrig', Childrens_Rights: '1,5', Childrens_Rights_risk: 'Niedrig', GSI: '0,01', GSI_risk: 'Niedrig', NSC: '1,02', NSC_risk: 'Niedrig', WJP: '0,9', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,98', HFI_risk: 'Niedrig', PFI: '97', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '77,9', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Niedrig' },
                { ID: 10, Jahr: '2022', Länderkürzel: 'DE', Land: 'Deutschland', CPI: '80', CPI_risk: 'Niedrig', Childrens_Rights: '2,3', Childrens_Rights_risk: 'Niedrig', GSI: '0,01', GSI_risk: 'Niedrig', NSC: '1,02', NSC_risk: 'Niedrig', WJP: '0,84', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,73', HFI_risk: 'Niedrig', PFI: '94', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '62,4', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Niedrig' },
                { ID: 11, Jahr: '2022', Länderkürzel: 'EE', Land: 'Estland', CPI: '74', CPI_risk: 'Niedrig', Childrens_Rights: '2', Childrens_Rights_risk: 'Niedrig', GSI: '0,292', GSI_risk: 'Niedrig', NSC: '2,23', NSC_risk: 'Niedrig', WJP: '0,81', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,91', HFI_risk: 'Niedrig', PFI: '94', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '61,4', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Mittel' },
                { ID: 12, Jahr: '2022', Länderkürzel: 'FI', Land: 'Finnland', CPI: '88', CPI_risk: 'Niedrig', Childrens_Rights: '1,9', Childrens_Rights_risk: 'Niedrig', GSI: '0,082', GSI_risk: 'Niedrig', NSC: '1,44', NSC_risk: 'Niedrig', WJP: '0,88', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,85', HFI_risk: 'Niedrig', PFI: '100', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '76,5', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Niedrig' },
                { ID: 13, Jahr: '2022', Länderkürzel: 'FR', Land: 'Frankreich', CPI: '71', CPI_risk: 'Niedrig', Childrens_Rights: '1,1', Childrens_Rights_risk: 'Niedrig', GSI: '0,153', GSI_risk: 'Niedrig', NSC: '2,57', NSC_risk: 'Niedrig', WJP: '0,72', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,34', HFI_risk: 'Niedrig', PFI: '90', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '62,5', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Mittel' },
                { ID: 14, Jahr: '2022', Länderkürzel: 'GR', Land: 'Griechenland', CPI: '49', CPI_risk: 'Mittel', Childrens_Rights: '3,5', Childrens_Rights_risk: 'Mittel', GSI: '0,371', GSI_risk: 'Mittel', NSC: '0,9', NSC_risk: 'Niedrig', WJP: '0,61', WJP_risk: 'Mittel', ITUC: '4', ITUC_risk: 'Hoch', HFI: '7,86', HFI_risk: 'Mittel', PFI: '87', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Hoch', EPI: '56,2', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 15, Jahr: '2022', Länderkürzel: 'IN', Land: 'Indien', CPI: '40', CPI_risk: 'Mittel', Childrens_Rights: '4,9', Childrens_Rights_risk: 'Mittel', GSI: '0,555', GSI_risk: 'Mittel', NSC: '116,8', NSC_risk: 'Hoch', WJP: '0,5', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '6,39', HFI_risk: 'Hoch', PFI: '67', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '18,9', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 16, Jahr: '2022', Länderkürzel: 'IE', Land: 'Irland', CPI: '74', CPI_risk: 'Niedrig', Childrens_Rights: '2,5', Childrens_Rights_risk: 'Niedrig', GSI: '0,104', GSI_risk: 'Niedrig', NSC: '2,51', NSC_risk: 'Niedrig', WJP: '0,81', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,9', HFI_risk: 'Niedrig', PFI: '97', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '57,4', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 17, Jahr: '2022', Länderkürzel: 'IS', Land: 'Island', CPI: '74', CPI_risk: 'Niedrig', Childrens_Rights: '1,7', Childrens_Rights_risk: 'Niedrig', GSI: '0,042', GSI_risk: 'Niedrig', NSC: '0', NSC_risk: 'Niedrig', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,77', HFI_risk: 'Niedrig', PFI: '94', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '62,8', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Niedrig' },
                { ID: 18, Jahr: '2022', Länderkürzel: 'IL', Land: 'Israel', CPI: '59', CPI_risk: 'Mittel', Childrens_Rights: '2,5', Childrens_Rights_risk: 'Niedrig', GSI: '0,364', GSI_risk: 'Mittel', NSC: '1,39', NSC_risk: 'Niedrig', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: '2', ITUC_risk: 'Mittel', HFI: '7,66', HFI_risk: 'Mittel', PFI: '76', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '48,2', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 19, Jahr: '2022', Länderkürzel: 'IT', Land: 'Italien', CPI: '56', CPI_risk: 'Mittel', Childrens_Rights: '2,4', Childrens_Rights_risk: 'Niedrig', GSI: '0,283', GSI_risk: 'Niedrig', NSC: '2,42', NSC_risk: 'Niedrig', WJP: '0,66', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,49', HFI_risk: 'Niedrig', PFI: '90', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '57,7', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 20, Jahr: '2022', Länderkürzel: 'JP', Land: 'Japan', CPI: '73', CPI_risk: 'Niedrig', Childrens_Rights: '2,4', Childrens_Rights_risk: 'Niedrig', GSI: '0,138', GSI_risk: 'Niedrig', NSC: '1,34', NSC_risk: 'Niedrig', WJP: '0,79', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,73', HFI_risk: 'Niedrig', PFI: '96', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '57,2', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 21, Jahr: '2022', Länderkürzel: 'CA', Land: 'Kanada', CPI: '74', CPI_risk: 'Niedrig', Childrens_Rights: '3,3', Childrens_Rights_risk: 'Niedrig', GSI: '0,102', GSI_risk: 'Niedrig', NSC: '4,87', NSC_risk: 'Niedrig', WJP: '0,8', WJP_risk: 'Niedrig', ITUC: '3', ITUC_risk: 'Mittel', HFI: '8,85', HFI_risk: 'Niedrig', PFI: '98', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '50', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 22, Jahr: '2022', Länderkürzel: 'KE', Land: 'Kenia', CPI: '30', CPI_risk: 'Hoch', Childrens_Rights: '5,6', Childrens_Rights_risk: 'Mittel', GSI: '0,706', GSI_risk: 'Hoch', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,44', WJP_risk: 'Mittel', ITUC: '4', ITUC_risk: 'Hoch', HFI: '6,73', HFI_risk: 'Mittel', PFI: '48', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '30,8', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 23, Jahr: '2022', Länderkürzel: 'XK', Land: 'Kosovo', CPI: '39', CPI_risk: 'Hoch', Childrens_Rights: '4,5', Childrens_Rights_risk: 'Mittel', GSI: '0,438', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,55', WJP_risk: 'Mittel', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: 'Keine Daten', HFI_risk: 'Keine Daten', PFI: '56', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: 'Keine Daten', EPI_risk: 'Keine Daten', Umweltrisiko_sum_risk: 'Keine Daten', country_sum_risk: 'Hoch' },
                { ID: 24, Jahr: '2022', Länderkürzel: 'HR', Land: 'Kroatien', CPI: '47', CPI_risk: 'Mittel', Childrens_Rights: '2,2', Childrens_Rights_risk: 'Niedrig', GSI: '0,327', GSI_risk: 'Mittel', NSC: '2,71', NSC_risk: 'Niedrig', WJP: '0,61', WJP_risk: 'Mittel', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,16', HFI_risk: 'Mittel', PFI: '85', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '60,2', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 25, Jahr: '2022', Länderkürzel: 'CU', Land: 'Kuba', CPI: '46', CPI_risk: 'Mittel', Childrens_Rights: '3,2', Childrens_Rights_risk: 'Niedrig', GSI: '0,524', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: 'Keine Daten', HFI_risk: 'Keine Daten', PFI: '12', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '47,5', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 26, Jahr: '2022', Länderkürzel: 'LV', Land: 'Lettland', CPI: '59', CPI_risk: 'Mittel', Childrens_Rights: '2,5', Childrens_Rights_risk: 'Niedrig', GSI: '0,246', GSI_risk: 'Niedrig', NSC: '3,32', NSC_risk: 'Niedrig', WJP: '0,71', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,67', HFI_risk: 'Niedrig', PFI: '88', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '61,1', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Mittel' },
                { ID: 27, Jahr: '2022', Länderkürzel: 'LI', Land: 'Liechtenstein', CPI: 'Keine Daten', CPI_risk: 'Keine Daten', Childrens_Rights: '2,7', Childrens_Rights_risk: 'Niedrig', GSI: 'Keine Daten', GSI_risk: 'Keine Daten', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: 'Keine Daten', HFI_risk: 'Keine Daten', PFI: '90', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: 'Keine Daten', EPI_risk: 'Keine Daten', Umweltrisiko_sum_risk: 'Keine Daten', country_sum_risk: 'Hoch' },
                { ID: 28, Jahr: '2022', Länderkürzel: 'LT', Land: 'Litauen', CPI: '61', CPI_risk: 'Mittel', Childrens_Rights: '2', Childrens_Rights_risk: 'Niedrig', GSI: '0,262', GSI_risk: 'Niedrig', NSC: '4,21', NSC_risk: 'Niedrig', WJP: '0,75', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,68', HFI_risk: 'Niedrig', PFI: '89', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '55,9', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 29, Jahr: '2022', Länderkürzel: 'LU', Land: 'Luxemburg', CPI: '81', CPI_risk: 'Niedrig', Childrens_Rights: '2', Childrens_Rights_risk: 'Niedrig', GSI: '0,084', GSI_risk: 'Niedrig', NSC: '3,3', NSC_risk: 'Niedrig', WJP: '0,83', WJP_risk: 'Niedrig', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: '8,8', HFI_risk: 'Niedrig', PFI: '97', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '72,3', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Niedrig' },
                { ID: 30, Jahr: '2022', Länderkürzel: 'MT', Land: 'Malta', CPI: '54', CPI_risk: 'Mittel', Childrens_Rights: '2,7', Childrens_Rights_risk: 'Niedrig', GSI: 'Keine Daten', GSI_risk: 'Keine Daten', NSC: '2,67', NSC_risk: 'Niedrig', WJP: '0,68', WJP_risk: 'Niedrig', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: '8,45', HFI_risk: 'Niedrig', PFI: '89', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '75,2', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Mittel' },
                { ID: 31, Jahr: '2022', Länderkürzel: 'MA', Land: 'Marokko', CPI: '39', CPI_risk: 'Hoch', Childrens_Rights: '3,6', Childrens_Rights_risk: 'Mittel', GSI: '0,483', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,49', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '5,9', HFI_risk: 'Hoch', PFI: '37', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '28,4', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 32, Jahr: '2022', Länderkürzel: 'MK', Land: 'Nordmazedonien', CPI: '39', CPI_risk: 'Hoch', Childrens_Rights: '2,7', Childrens_Rights_risk: 'Niedrig', GSI: '0,456', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: 'Keine Daten', HFI_risk: 'Keine Daten', PFI: 'Keine Daten', PFI_risk: 'Keine Daten', Menschenrechte_sum_risk: 'Hoch', EPI: '54,3', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 33, Jahr: '2022', Länderkürzel: 'MD', Land: 'Moldau', CPI: '36', CPI_risk: 'Hoch', Childrens_Rights: '3,5', Childrens_Rights_risk: 'Mittel', GSI: '0,416', GSI_risk: 'Mittel', NSC: '5,2', NSC_risk: 'Mittel', WJP: '0,51', WJP_risk: 'Mittel', ITUC: '2', ITUC_risk: 'Mittel', HFI: '7,68', HFI_risk: 'Mittel', PFI: '62', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '42,7', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 34, Jahr: '2022', Länderkürzel: 'MC', Land: 'Monaco', CPI: 'Keine Daten', CPI_risk: 'Keine Daten', Childrens_Rights: '2,1', Childrens_Rights_risk: 'Niedrig', GSI: 'Keine Daten', GSI_risk: 'Keine Daten', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: 'Keine Daten', HFI_risk: 'Keine Daten', PFI: '84', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: 'Keine Daten', EPI_risk: 'Keine Daten', Umweltrisiko_sum_risk: 'Keine Daten', country_sum_risk: 'Hoch' },
                { ID: 35, Jahr: '2022', Länderkürzel: 'ME', Land: 'Montenegro', CPI: '46', CPI_risk: 'Mittel', Childrens_Rights: '3,2', Childrens_Rights_risk: 'Niedrig', GSI: '0,358', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: '2', ITUC_risk: 'Mittel', HFI: '7,88', HFI_risk: 'Mittel', PFI: '67', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Mittel', EPI: '46,9', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 36, Jahr: '2022', Länderkürzel: 'NL', Land: 'Niederlande', CPI: '82', CPI_risk: 'Niedrig', Childrens_Rights: '2,4', Childrens_Rights_risk: 'Niedrig', GSI: '0,061', GSI_risk: 'Niedrig', NSC: '0,5', NSC_risk: 'Niedrig', WJP: '0,83', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,78', HFI_risk: 'Niedrig', PFI: '98', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '62,6', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Niedrig' },
                { ID: 37, Jahr: '2022', Länderkürzel: 'NO', Land: 'Norwegen', CPI: '85', CPI_risk: 'Niedrig', Childrens_Rights: '2,2', Childrens_Rights_risk: 'Niedrig', GSI: '0,045', GSI_risk: 'Niedrig', NSC: '1,48', NSC_risk: 'Niedrig', WJP: '0,9', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,76', HFI_risk: 'Niedrig', PFI: '100', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '59,3', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 38, Jahr: '2022', Länderkürzel: 'AT', Land: 'Österreich', CPI: '74', CPI_risk: 'Niedrig', Childrens_Rights: '2,3', Childrens_Rights_risk: 'Niedrig', GSI: '0,034', GSI_risk: 'Niedrig', NSC: '2', NSC_risk: 'Niedrig', WJP: '0,81', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,67', HFI_risk: 'Niedrig', PFI: '93', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '66,5', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Niedrig' },
                { ID: 39, Jahr: '2022', Länderkürzel: 'PA', Land: 'Panama', CPI: '36', CPI_risk: 'Hoch', Childrens_Rights: '3,1', Childrens_Rights_risk: 'Niedrig', GSI: '0,364', GSI_risk: 'Mittel', NSC: '0,01', NSC_risk: 'Niedrig', WJP: '0,52', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '8,12', HFI_risk: 'Mittel', PFI: '83', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Hoch', EPI: '50,5', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 40, Jahr: '2022', Länderkürzel: 'PE', Land: 'Peru', CPI: '36', CPI_risk: 'Hoch', Childrens_Rights: '4,2', Childrens_Rights_risk: 'Mittel', GSI: '0,443', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,49', WJP_risk: 'Mittel', ITUC: '4', ITUC_risk: 'Hoch', HFI: '7,93', HFI_risk: 'Mittel', PFI: '72', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Hoch', EPI: '39,8', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 41, Jahr: '2022', Länderkürzel: 'PL', Land: 'Polen', CPI: '56', CPI_risk: 'Mittel', Childrens_Rights: '2,4', Childrens_Rights_risk: 'Niedrig', GSI: '0,244', GSI_risk: 'Niedrig', NSC: '1,89', NSC_risk: 'Niedrig', WJP: '0,64', WJP_risk: 'Niedrig', ITUC: '3', ITUC_risk: 'Mittel', HFI: '7,96', HFI_risk: 'Mittel', PFI: '82', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '50,6', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 42, Jahr: '2022', Länderkürzel: 'PT', Land: 'Portugal', CPI: '62', CPI_risk: 'Mittel', Childrens_Rights: '2,6', Childrens_Rights_risk: 'Niedrig', GSI: '0,085', GSI_risk: 'Niedrig', NSC: '3,54', NSC_risk: 'Niedrig', WJP: '0,7', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,69', HFI_risk: 'Niedrig', PFI: '95', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '50,4', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 43, Jahr: '2022', Länderkürzel: 'RO', Land: 'Rumänien', CPI: '45', CPI_risk: 'Mittel', Childrens_Rights: '3', Childrens_Rights_risk: 'Niedrig', GSI: '0,339', GSI_risk: 'Mittel', NSC: '2,88', NSC_risk: 'Niedrig', WJP: '0,63', WJP_risk: 'Niedrig', ITUC: '4', ITUC_risk: 'Hoch', HFI: '8,33', HFI_risk: 'Niedrig', PFI: '83', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Hoch', EPI: '56', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 44, Jahr: '2022', Länderkürzel: 'RU', Land: 'Russland', CPI: '29', CPI_risk: 'Hoch', Childrens_Rights: '3,1', Childrens_Rights_risk: 'Niedrig', GSI: '0,516', GSI_risk: 'Mittel', NSC: '11,9', NSC_risk: 'Hoch', WJP: '0,46', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '6,23', HFI_risk: 'Hoch', PFI: '20', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '37,5', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 45, Jahr: '2022', Länderkürzel: 'SE', Land: 'Schweden', CPI: '85', CPI_risk: 'Niedrig', Childrens_Rights: '1,7', Childrens_Rights_risk: 'Niedrig', GSI: '0,043', GSI_risk: 'Niedrig', NSC: '1', NSC_risk: 'Niedrig', WJP: '0,86', WJP_risk: 'Niedrig', ITUC: '1', ITUC_risk: 'Niedrig', HFI: '8,83', HFI_risk: 'Niedrig', PFI: '100', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Niedrig', EPI: '72,7', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Niedrig' },
                { ID: 46, Jahr: '2022', Länderkürzel: 'CH', Land: 'Schweiz', CPI: '84', CPI_risk: 'Niedrig', Childrens_Rights: '2,7', Childrens_Rights_risk: 'Niedrig', GSI: '0,015', GSI_risk: 'Niedrig', NSC: '1,1', NSC_risk: 'Niedrig', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: '2', ITUC_risk: 'Mittel', HFI: '9,11', HFI_risk: 'Niedrig', PFI: '96', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '65,9', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Mittel' },
                { ID: 47, Jahr: '2022', Länderkürzel: 'RS', Land: 'Serbien', CPI: '38', CPI_risk: 'Hoch', Childrens_Rights: '3,4', Childrens_Rights_risk: 'Mittel', GSI: '0,339', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,49', WJP_risk: 'Mittel', ITUC: '4', ITUC_risk: 'Hoch', HFI: '7,54', HFI_risk: 'Mittel', PFI: '62', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '43,9', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 48, Jahr: '2022', Länderkürzel: 'SG', Land: 'Singapur', CPI: 'Keine Daten', CPI_risk: 'Keine Daten', Childrens_Rights: '3,8', Childrens_Rights_risk: 'Mittel', GSI: '0,134', GSI_risk: 'Niedrig', NSC: '0,9', NSC_risk: 'Niedrig', WJP: '0,78', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '7,98', HFI_risk: 'Mittel', PFI: '48', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Mittel', EPI: '50,9', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 49, Jahr: '2022', Länderkürzel: 'SK', Land: 'Slowakei', CPI: '52', CPI_risk: 'Mittel', Childrens_Rights: '2,2', Childrens_Rights_risk: 'Niedrig', GSI: '0,272', GSI_risk: 'Niedrig', NSC: '1,49', NSC_risk: 'Niedrig', WJP: '0,66', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,21', HFI_risk: 'Niedrig', PFI: '95', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '60', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 50, Jahr: '2022', Länderkürzel: 'SI', Land: 'Slowenien', CPI: '57', CPI_risk: 'Mittel', Childrens_Rights: '2', Childrens_Rights_risk: 'Niedrig', GSI: '0,201', GSI_risk: 'Niedrig', NSC: '1,64', NSC_risk: 'Niedrig', WJP: '0,68', WJP_risk: 'Niedrig', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: '8,37', HFI_risk: 'Niedrig', PFI: '90', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '67,3', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Mittel' },
                { ID: 51, Jahr: '2022', Länderkürzel: 'ES', Land: 'Spanien', CPI: '61', CPI_risk: 'Mittel', Childrens_Rights: '2,3', Childrens_Rights_risk: 'Niedrig', GSI: '0,128', GSI_risk: 'Niedrig', NSC: '1,78', NSC_risk: 'Niedrig', WJP: '0,73', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,56', HFI_risk: 'Niedrig', PFI: '90', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '56,6', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 52, Jahr: '2022', Länderkürzel: 'ZA', Land: 'Südafrika', CPI: '44', CPI_risk: 'Mittel', Childrens_Rights: '3,6', Childrens_Rights_risk: 'Mittel', GSI: '0,538', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,58', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '7,3', HFI_risk: 'Mittel', PFI: '79', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '37,2', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 53, Jahr: '2022', Länderkürzel: 'TH', Land: 'Thailand', CPI: '35', CPI_risk: 'Hoch', Childrens_Rights: '3,8', Childrens_Rights_risk: 'Mittel', GSI: '0,511', GSI_risk: 'Mittel', NSC: '5,27', NSC_risk: 'Mittel', WJP: '0,5', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '6,89', HFI_risk: 'Mittel', PFI: '29', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '38,1', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 54, Jahr: '2022', Länderkürzel: 'CZ', Land: 'Tschechien', CPI: '54', CPI_risk: 'Mittel', Childrens_Rights: '3', Childrens_Rights_risk: 'Niedrig', GSI: '0,191', GSI_risk: 'Niedrig', NSC: '2,32', NSC_risk: 'Niedrig', WJP: '0,73', WJP_risk: 'Niedrig', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,61', HFI_risk: 'Niedrig', PFI: '91', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '59,9', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 55, Jahr: '2022', Länderkürzel: 'TN', Land: 'Tunesien', CPI: '44', CPI_risk: 'Mittel', Childrens_Rights: '3,7', Childrens_Rights_risk: 'Mittel', GSI: '0,392', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,53', WJP_risk: 'Mittel', ITUC: '4', ITUC_risk: 'Hoch', HFI: '6,46', HFI_risk: 'Hoch', PFI: '64', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '40,7', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 56, Jahr: '2022', Länderkürzel: 'TR', Land: 'Türkei', CPI: '38', CPI_risk: 'Hoch', Childrens_Rights: '3,7', Childrens_Rights_risk: 'Mittel', GSI: '0,516', GSI_risk: 'Mittel', NSC: '5,95', NSC_risk: 'Mittel', WJP: '0,42', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '5,79', HFI_risk: 'Hoch', PFI: '32', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '26,3', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 57, Jahr: '2022', Länderkürzel: 'UA', Land: 'Ukraine', CPI: '32', CPI_risk: 'Hoch', Childrens_Rights: '3,3', Childrens_Rights_risk: 'Niedrig', GSI: '0,544', GSI_risk: 'Mittel', NSC: '5,4', NSC_risk: 'Mittel', WJP: '0,51', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '6,86', HFI_risk: 'Mittel', PFI: '61', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '49,6', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 58, Jahr: '2022', Länderkürzel: 'HU', Land: 'Ungarn', CPI: '43', CPI_risk: 'Mittel', Childrens_Rights: '2,4', Childrens_Rights_risk: 'Niedrig', GSI: '0,236', GSI_risk: 'Niedrig', NSC: '1,39', NSC_risk: 'Niedrig', WJP: '0,52', WJP_risk: 'Mittel', ITUC: '4', ITUC_risk: 'Hoch', HFI: '7,73', HFI_risk: 'Mittel', PFI: '69', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '55,1', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 59, Jahr: '2022', Länderkürzel: 'US', Land: 'USA', CPI: '67', CPI_risk: 'Mittel', Childrens_Rights: '4,4', Childrens_Rights_risk: 'Mittel', GSI: '0,159', GSI_risk: 'Niedrig', NSC: '5,25', NSC_risk: 'Mittel', WJP: '0,69', WJP_risk: 'Niedrig', ITUC: '4', ITUC_risk: 'Hoch', HFI: '8,73', HFI_risk: 'Niedrig', PFI: '83', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Hoch', EPI: '51,1', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 60, Jahr: '2022', Länderkürzel: 'AE', Land: 'Vereinigte Arabische Emirate', CPI: '69', CPI_risk: 'Mittel', Childrens_Rights: '5,7', Childrens_Rights_risk: 'Mittel', GSI: '0,268', GSI_risk: 'Niedrig', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,64', WJP_risk: 'Niedrig', ITUC: '5', ITUC_risk: 'Hoch', HFI: '6,06', HFI_risk: 'Hoch', PFI: '17', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '52,4', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 61, Jahr: '2022', Länderkürzel: 'GB', Land: 'Großbritannien', CPI: '78', CPI_risk: 'Niedrig', Childrens_Rights: '2,2', Childrens_Rights_risk: 'Niedrig', GSI: '0,111', GSI_risk: 'Niedrig', NSC: '0,83', NSC_risk: 'Niedrig', WJP: '0,79', WJP_risk: 'Niedrig', ITUC: '3', ITUC_risk: 'Mittel', HFI: '8,75', HFI_risk: 'Niedrig', PFI: '93', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '77,7', EPI_risk: 'Niedrig', Umweltrisiko_sum_risk: 'Niedrig', country_sum_risk: 'Mittel' },
                { ID: 62, Jahr: '2022', Länderkürzel: 'CY', Land: 'Zypern', CPI: '53', CPI_risk: 'Mittel', Childrens_Rights: '3,1', Childrens_Rights_risk: 'Niedrig', GSI: '0,191', GSI_risk: 'Niedrig', NSC: '4,45', NSC_risk: 'Niedrig', WJP: '0,68', WJP_risk: 'Niedrig', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: '8,42', HFI_risk: 'Niedrig', PFI: '93', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Mittel', EPI: '58', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Mittel' },
                { ID: 63, Jahr: '2022', Länderkürzel: 'BD', Land: 'Bangladesch', CPI: '26', CPI_risk: 'Hoch', Childrens_Rights: '5,9', Childrens_Rights_risk: 'Mittel', GSI: '0,501', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,4', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '5,75', HFI_risk: 'Hoch', PFI: '39', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '23,1', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 64, Jahr: '2022', Länderkürzel: 'HK', Land: 'Hongkong', CPI: 'Keine Daten', CPI_risk: 'Keine Daten', Childrens_Rights: 'Keine Daten', Childrens_Rights_risk: 'Hoch', GSI: '0,247', GSI_risk: 'Niedrig', NSC: '6,78', NSC_risk: 'Mittel', WJP: '0,75', WJP_risk: 'Niedrig', ITUC: '5', ITUC_risk: 'Hoch', HFI: '8,41', HFI_risk: 'Niedrig', PFI: '43', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: 'Keine Daten', EPI_risk: 'Keine Daten', Umweltrisiko_sum_risk: 'Keine Daten', country_sum_risk: 'Hoch' },
                { ID: 65, Jahr: '2022', Länderkürzel: 'ID', Land: 'Indonesien', CPI: '38', CPI_risk: 'Hoch', Childrens_Rights: '4,9', Childrens_Rights_risk: 'Mittel', GSI: '0,505', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,52', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '7,1', HFI_risk: 'Mittel', PFI: '59', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '28,2', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 66, Jahr: '2022', Länderkürzel: 'CO', Land: 'Kolombien', CPI: '39', CPI_risk: 'Hoch', Childrens_Rights: '4', Childrens_Rights_risk: 'Mittel', GSI: '0,516', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,49', WJP_risk: 'Mittel', ITUC: 'Keine Daten', ITUC_risk: 'Keine Daten', HFI: '7,01', HFI_risk: 'Mittel', PFI: '64', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: 'Keine Daten', EPI_risk: 'Keine Daten', Umweltrisiko_sum_risk: 'Keine Daten', country_sum_risk: 'Hoch' },
                { ID: 67, Jahr: '2022', Länderkürzel: 'MG', Land: 'Madagaskar', CPI: '26', CPI_risk: 'Hoch', Childrens_Rights: '5,9', Childrens_Rights_risk: 'Mittel', GSI: '0,62', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,44', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '7,02', HFI_risk: 'Mittel', PFI: '61', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '28', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 68, Jahr: '2022', Länderkürzel: 'MY', Land: 'Malaysia', CPI: '48', CPI_risk: 'Mittel', Childrens_Rights: '4,6', Childrens_Rights_risk: 'Mittel', GSI: '0,392', GSI_risk: 'Mittel', NSC: '3,1', NSC_risk: 'Niedrig', WJP: '0,57', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '7,17', HFI_risk: 'Mittel', PFI: '50', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '35', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 69, Jahr: '2022', Länderkürzel: 'MX', Land: 'Mexiko', CPI: '31', CPI_risk: 'Hoch', Childrens_Rights: '4,6', Childrens_Rights_risk: 'Mittel', GSI: '0,573', GSI_risk: 'Mittel', NSC: '8,23', NSC_risk: 'Mittel', WJP: '0,43', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '6,92', HFI_risk: 'Mittel', PFI: '60', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '45,5', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 70, Jahr: '2022', Länderkürzel: 'MM', Land: 'Myanmar', CPI: '28', CPI_risk: 'Hoch', Childrens_Rights: '6,4', Childrens_Rights_risk: 'Mittel', GSI: '0,659', GSI_risk: 'Hoch', NSC: '7', NSC_risk: 'Mittel', WJP: '0,39', WJP_risk: 'Mittel', ITUC: '5+', ITUC_risk: 'Hoch', HFI: '5,78', HFI_risk: 'Hoch', PFI: '9', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '19,4', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 71, Jahr: '2022', Länderkürzel: 'NP', Land: 'Nepal', CPI: '33', CPI_risk: 'Hoch', Childrens_Rights: '5,5', Childrens_Rights_risk: 'Mittel', GSI: '0,441', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,52', WJP_risk: 'Mittel', ITUC: '3', ITUC_risk: 'Mittel', HFI: '7,12', HFI_risk: 'Mittel', PFI: '57', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '28,3', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 72, Jahr: '2022', Länderkürzel: 'PK', Land: 'Pakistan', CPI: '28', CPI_risk: 'Hoch', Childrens_Rights: '6,4', Childrens_Rights_risk: 'Mittel', GSI: '0,741', GSI_risk: 'Hoch', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,39', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '5,63', HFI_risk: 'Hoch', PFI: '37', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '24,6', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 73, Jahr: '2022', Länderkürzel: 'PH', Land: 'Philippinen', CPI: '33', CPI_risk: 'Hoch', Childrens_Rights: '4,5', Childrens_Rights_risk: 'Mittel', GSI: '0,602', GSI_risk: 'Mittel', NSC: '9,6', NSC_risk: 'Mittel', WJP: '0,46', WJP_risk: 'Mittel', ITUC: '5', ITUC_risk: 'Hoch', HFI: '6,83', HFI_risk: 'Mittel', PFI: '55', PFI_risk: 'Mittel', Menschenrechte_sum_risk: 'Hoch', EPI: '28,9', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' },
                { ID: 74, Jahr: '2022', Länderkürzel: 'KR', Land: 'Südkorea', CPI: '62', CPI_risk: 'Mittel', Childrens_Rights: '2,9', Childrens_Rights_risk: 'Niedrig', GSI: '0,298', GSI_risk: 'Mittel', NSC: '4,65', NSC_risk: 'Niedrig', WJP: '0,74', WJP_risk: 'Niedrig', ITUC: '5', ITUC_risk: 'Hoch', HFI: '8,39', HFI_risk: 'Niedrig', PFI: '83', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Hoch', EPI: '46,9', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 75, Jahr: '2022', Länderkürzel: 'TW', Land: 'Taiwan', CPI: '68', CPI_risk: 'Mittel', Childrens_Rights: 'Keine Daten', Childrens_Rights_risk: 'Hoch', GSI: '0,203', GSI_risk: 'Niedrig', NSC: '4,5', NSC_risk: 'Niedrig', WJP: 'Keine Daten', WJP_risk: 'Keine Daten', ITUC: '2', ITUC_risk: 'Mittel', HFI: '8,68', HFI_risk: 'Niedrig', PFI: '94', PFI_risk: 'Niedrig', Menschenrechte_sum_risk: 'Hoch', EPI: '45,3', EPI_risk: 'Mittel', Umweltrisiko_sum_risk: 'Mittel', country_sum_risk: 'Hoch' },
                { ID: 76, Jahr: '2022', Länderkürzel: 'VN', Land: 'Vietnam', CPI: '39', CPI_risk: 'Hoch', Childrens_Rights: '5,6', Childrens_Rights_risk: 'Mittel', GSI: '0,415', GSI_risk: 'Mittel', NSC: 'Keine Daten', NSC_risk: 'Keine Daten', WJP: '0,49', WJP_risk: 'Mittel', ITUC: '4', ITUC_risk: 'Hoch', HFI: '5,9', HFI_risk: 'Hoch', PFI: '19', PFI_risk: 'Hoch', Menschenrechte_sum_risk: 'Hoch', EPI: '20,1', EPI_risk: 'Hoch', Umweltrisiko_sum_risk: 'Hoch', country_sum_risk: 'Hoch' }
            ],
        }
    },
    created() {
        this.fetchProjects();
    },
    watch: {
        '$route': 'fetchProjects'
    },
    methods: {
        ...mapActions({
            fetchProjects: 'projects/fetchProjects',
        }),
        moveItemUp(projectId) {
            this.$store.commit('loading/setLoading', true);
            ProjectService.moveProjectUp(projectId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchProjects();
                });
        },
        moveItemDown(projectId) {
            this.$store.commit('loading/setLoading', true);
            ProjectService.moveProjectDown(projectId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchProjects();
                });
        }
    },
    computed: {
        ...mapGetters({
        }),
        fields_category() {
            return [
                { key: 'ID', sortable: true },
                { key: 'Jahr', sortable: true },
                { key: 'NACE', sortable: true },
                { key: 'Branche', sortable: true },
                { key: 'Forschungsbericht', sortable: true },
                { key: 'EU_Supply_Chain_Act', sortable: true },
                { key: 'Risiko_Branche', sortable: true },
                { key: 'Details', sortable: false }
            ];
        },
        fields_country() {
            return [
                { key: 'ID',                        label: 'ID'                                 , sortable: true },
                { key: 'Jahr',                      label: 'Jahr'                               , sortable: true },
                { key: 'Länderkürzel',              label: 'Länderkürzel'                       , sortable: true },
                { key: 'Land',                      label: 'Land'                               , sortable: true, stickyColumn: true,  isRowHeader: true },
                { key: 'country_sum_risk',          label: 'Länderrisiken (Summe)'              , sortable: true, variant: 'info' },
                { key: 'CPI',                       label: 'CPI'                                , sortable: true },
                { key: 'CPI_risk',                  label: 'CPI_risk'                           , sortable: true },
                { key: 'Childrens_Rights',          label: 'Childrens_Rights'                   , sortable: true },
                { key: 'Childrens_Rights_risk',     label: 'Childrens_Rights_risk'              , sortable: true },
                { key: 'GSI',                       label: 'GSI'                                , sortable: true },
                { key: 'GSI_risk',                  label: 'GSI_risk'                           , sortable: true },
                { key: 'NSC',                       label: 'NSC'                                , sortable: true },
                { key: 'NSC_risk',                  label: 'NSC_risk'                           , sortable: true },
                { key: 'WJP',                       label: 'WJP'                                , sortable: true },
                { key: 'WJP_risk',                  label: 'WJP_risk'                           , sortable: true },
                { key: 'ITUC',                      label: 'ITUC'                               , sortable: true },
                { key: 'ITUC_risk',                 label: 'ITUC_risk'                          , sortable: true },
                { key: 'HFI',                       label: 'HFI'                                , sortable: true },
                { key: 'HFI_risk',                  label: 'HFI_risk'                           , sortable: true },
                { key: 'PFI',                       label: 'PFI'                                , sortable: true },
                { key: 'PFI_risk',                  label: 'PFI_risk'                           , sortable: true },
                { key: 'Menschenrechte_sum_risk',   label: 'Risiken Menschenrechte (Summe)'     , sortable: true, variant: 'info' },
                { key: 'EPI',                       label: 'EPI'                                , sortable: true },
                { key: 'EPI_risk',                  label: 'EPI_risk'                           , sortable: true },
                { key: 'Umweltrisiko_sum_risk',     label: 'Umweltrisiken (Summe)'              , sortable: true, variant: 'info' }
            ];
        },
    }
};
</script>
