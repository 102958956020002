<template>
<div class="card shadow">
    <div class="card-header">{{ $t("questionnaire.download_header") }}</div>
    <div class="card-body">
       <h2>{{ $t("questionnaire.download_note") }}</h2>
    </div>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import QuestionnaireService from '../../../../services/QuestionnaireService';
import DownloadService from '../../../../services/DownloadService';

export default {
    name: 'questionnaire-download',
    data() {
        return {
        }
    },
    created() {
        this.downloadFile(this.$route.params.downloadKey, this.$route.params.fileName);
    },
    watch: {
    },
    methods: {
        downloadFile(downloadKey, filename) {
            this.$store.commit('loading/setLoading', true);
            QuestionnaireService.downloadFile(downloadKey, filename)
                .then(response => {
                    DownloadService.processDownload(response, filename);
                    this.$store.commit('loading/setLoading', false);
                })
        }
    },
    computed: {

    }
}
</script>